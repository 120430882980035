import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useStyles } from "styles/hospitalPageStyles";
import { Button, TableCell, TableRow } from "@mui/material";
import ProfileInfo from "components/Utilities/ProfileInfo";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const HospitalRow = ({ rowData }) => {
  const classes = useStyles();
  const { _id, name, icon } = rowData;
  return (
    <TableRow hover tabIndex={-1} key={_id}>
      <TableCell align="left" className={classes.tableCell}>
        <ProfileInfo
          imgUrl={icon && icon}
          firstName={name ? name : "No name"}
          lastName=""
        />
      </TableCell>
      <TableCell
        align="left"
        className={classes.tableCell}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Link to={`/plans/hospitals/${_id}`}>
          <Button
            className={classes.viewBtn}
            endIcon={<ArrowForwardIosIcon />}
            sx={{ textDecoration: "none" }}
          >
            View Plan
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

HospitalRow.propTypes = {
  index: PropTypes.number.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default HospitalRow;
