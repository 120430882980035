import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { EnhancedTable } from "components/layouts";
import CloseIcon from "components/Icons/CloseIcon";
import { dateMoment } from "components/Utilities/Time";
import TableLayout from "components/layouts/TableLayout";
import { testRefTableHeader } from "components/Utilities/tableHeaders";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Modal,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  parentGridWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.1)",

    "&:not(:last-of-type)": {
      marginBottom: "5rem",
    },
  },

  infoBadge: {
    "&.MuiChip-root": {
      fontSize: "1.5rem",
      borderRadius: "1.5rem",
    },
  },

  title: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
      whitespace: "wrap",
    },
  },
  item: {
    padding: "2rem 3rem",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "nowrap",
    // justifyContent: "space-between",
    "&.MuiGrid-root > *": {
      flex: 1,
    },
  },
  subItem: {
    "&.MuiGrid-container": {
      flexDirection: "column",
      wordBreak: "break-word",
    },
    // "&:nth-child(1) > p": {
    //   color: "green",
    //   marginTop: "1rem",
    // },
  },
}));

export const TestRefModal = ({
  isOpen,
  handleDialogClose,
  consultationInfo,
  testRefInfo,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const testArr = testRefInfo?.tests || [];
  const { doctorData, doctorNote, symptoms } = consultationInfo;

  return (
    <Modal
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "90vh",
          bgcolor: "background.paper",
          borderRadius: "2rem",
        }}
        width="90vw"
        padding={{ sm: 4, xs: 3, md: 4 }}
      >
        <Grid sx={{ height: "100%", overflowY: "auto" }}>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            flex="2"
          >
            <Grid item>
              <Typography variant="h3" sx={{ fontWeight: 500 }}>
                {testArr?.length > 1 ? "Test Referrals" : "Test Referral"}
              </Typography>
            </Grid>

            <CloseIcon
              onClick={handleDialogClose}
              style={{
                fontSize: "3.5rem",
                cursor: "pointer",
              }}
            />
          </Grid>
          <Grid item container width="100%" direction="row">
            <Grid item padding={{ md: "2rem 0", sm: "1rem 0", xs: "1rem 0" }}>
              <Grid item>
                <Grid item container alignItems="center" gap={1}>
                  <Typography variant="body1" className={classes.title}>
                    Doctor:
                  </Typography>
                  {doctorData && Object.keys(doctorData).length > 0 ? (
                    <Typography variant="h5">{`${doctorData?.firstName} ${doctorData?.lastName}`}</Typography>
                  ) : (
                    <Typography variant="h5">No Doctor</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container alignItems="center" gap={1}>
                  <Typography variant="body1" className={classes.title}>
                    Test Referral Date:
                  </Typography>
                  <Typography variant="h5">
                    {dateMoment(testArr?.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container alignItems="center" gap={1}>
                  <Typography variant="body1" className={classes.title}>
                    Symptoms:
                  </Typography>
                  <Grid item>
                    <Grid container gap={1} flexWrap="wrap">
                      {symptoms ? (
                        symptoms.map((i) => {
                          return (
                            <Typography key={i.name} variant="h5">
                              {i?.name}
                            </Typography>
                          );
                        })
                      ) : (
                        <Typography variant="h5">No Value</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container alignItems="center" gap={1}>
                  <Typography variant="body1" className={classes.title}>
                    Urgency:
                  </Typography>
                  <Chip label={testRefInfo?.urgency} color="primary" />
                </Grid>
              </Grid>
            </Grid>
            <Divider color={theme.palette.common.lighterGrey} />
            <TableLayout>
              <Grid
                container
                item
                direction="column"
                overflow="hidden"
                maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
              >
                <EnhancedTable
                  headCells={testRefTableHeader}
                  rows={[]}
                  paginationLabel="Patients per page"
                  hasCheckbox={false}
                  hasPagination={false}
                >
                  {testArr.map((test, idx) => {
                    const labelId = `enhanced-table-checkbox-${idx}`;
                    return (
                      <TableRow key={idx} hover tabIndex={-1}>
                        <TableCell
                          id={labelId}
                          scope="row"
                          align="left"
                          className={classes.tableCell}
                          style={{
                            color: theme.palette.common.grey,
                            textAlign: "left",
                          }}
                        >
                          {test?.name}
                        </TableCell>
                        <TableCell
                          id={labelId}
                          scope="row"
                          align="left"
                          className={classes.tableCell}
                          style={{
                            color: theme.palette.common.grey,
                            textAlign: "left",
                          }}
                        >
                          {test?.tat}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </EnhancedTable>
              </Grid>
            </TableLayout>
            <Grid
              item
              container
              padding={{ md: "2rem 0", sm: "1rem 0", xs: "1rem 0", lg: "2rem" }}
              alignItems="center"
              justifyContent="space-between"
              sx={{ flexWrap: "nowrap" }}
            >
              <Grid
                item
                container
                direction="column"
                gap={{ lg: 0, md: 2, sm: 2, xs: 0 }}
              >
                <Grid item>
                  <Typography variant="body1" className={classes.title}>
                    Doctors Note:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ lineHeight: 1.85 }}>
                    {doctorNote ? doctorNote : "No Value"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

TestRefModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  testRefInfo: PropTypes.object.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  consultationInfo: PropTypes.object.isRequired,
};
