import { SvgIcon } from "@mui/material";
import React from "react";

function DeleteIcon(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M14.0004 4.48632C13.9871 4.48632 13.9671 4.48632 13.9471 4.48632C10.4204 4.13299 6.90043 3.99966 3.41377 4.35299L2.05377 4.48632C1.77377 4.51299 1.5271 4.31299 1.50043 4.03299C1.47377 3.75299 1.67377 3.51299 1.9471 3.48633L3.3071 3.35299C6.85377 2.99299 10.4471 3.13299 14.0471 3.48633C14.3204 3.51299 14.5204 3.75966 14.4938 4.03299C14.4738 4.29299 14.2538 4.48632 14.0004 4.48632Z" />
      <path d="M5.66651 3.81398C5.63984 3.81398 5.61318 3.81398 5.57984 3.80732C5.31318 3.76065 5.12651 3.50065 5.17318 3.23398L5.31984 2.36065C5.42651 1.72065 5.57318 0.833984 7.12651 0.833984H8.87318C10.4332 0.833984 10.5798 1.75398 10.6798 2.36732L10.8265 3.23398C10.8732 3.50732 10.6865 3.76732 10.4198 3.80732C10.1465 3.85398 9.88651 3.66732 9.84651 3.40065L9.69984 2.53398C9.60651 1.95398 9.58651 1.84065 8.87984 1.84065H7.13318C6.42651 1.84065 6.41318 1.93398 6.31318 2.52732L6.15984 3.39398C6.11984 3.64065 5.90651 3.81398 5.66651 3.81398Z" />
      <path d="M10.1396 15.1657H5.85961C3.53294 15.1657 3.43961 13.8791 3.36627 12.8391L2.93294 6.12574C2.91294 5.85241 3.12627 5.61241 3.39961 5.59241C3.67961 5.57907 3.91294 5.78574 3.93294 6.05907L4.36627 12.7724C4.43961 13.7857 4.46627 14.1657 5.85961 14.1657H10.1396C11.5396 14.1657 11.5663 13.7857 11.6329 12.7724L12.0663 6.05907C12.0863 5.78574 12.3263 5.57907 12.5996 5.59241C12.8729 5.61241 13.0863 5.84574 13.0663 6.12574L12.6329 12.8391C12.5596 13.8791 12.4663 15.1657 10.1396 15.1657Z" />
      <path d="M9.10672 11.5H6.88672C6.61339 11.5 6.38672 11.2733 6.38672 11C6.38672 10.7267 6.61339 10.5 6.88672 10.5H9.10672C9.38005 10.5 9.60672 10.7267 9.60672 11C9.60672 11.2733 9.38005 11.5 9.10672 11.5Z" />
      <path d="M9.66634 8.83398H6.33301C6.05967 8.83398 5.83301 8.60732 5.83301 8.33398C5.83301 8.06065 6.05967 7.83398 6.33301 7.83398H9.66634C9.93967 7.83398 10.1663 8.06065 10.1663 8.33398C10.1663 8.60732 9.93967 8.83398 9.66634 8.83398Z" />
    </SvgIcon>
  );
}

export default DeleteIcon;
