import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  badge: {
    "&.MuiChip-root": {
      fontSize: "12px !important",
      height: "2.7rem",
      borderRadius: "1.3rem",
      fontWeight: 500,
    },
  },
}));

const StatusPill = ({ type, label, size = "medium", onHandleClick }) => {
  const classes = useStyles();
  const bgColor =
    type === "success"
      ? "rgba(62, 165, 132, 0.1)"
      : type === "normal"
      ? "rgb(233,239,255)"
      : type === "error"
      ? "rgba(242, 24, 24, 0.1)"
      : type === "warning"
      ? "rgb(255,237,170, 0.3)"
      : "rgba(240, 240, 240, 1)";

  const textColor =
    type === "success"
      ? "#3EA584"
      : type === "normal"
      ? "#0046fa"
      : type === "error"
      ? "#f21818"
      : type === "warning"
      ? "#ffd333"
      : "#757886";

  return (
    <button
      style={{ border: "none", background: "transparent" }}
      onClick={(e) => {
        if (!onHandleClick) return;
        onHandleClick(e);
      }}
    >
      <Chip
        label={label}
        className={classes.badge}
        size={size}
        sx={{
          cursor: onHandleClick ? "pointer" : "default",
          backgroundColor: bgColor,
          color: textColor,
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      />
    </button>
  );
};

StatusPill.propTypes = {
  type: PropTypes.oneOf(["success", "normal", "error"]).isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onHandleClick: PropTypes.func,
};

export default StatusPill;
