import React from "react";

const CircularChart = () => {
  return (
    <svg
      width="177"
      height="176"
      viewBox="0 0 177 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path d="M153.558 39.4075C156.47 37.2376 157.09 33.0987 154.708 30.3569C144.333 18.4142 130.889 9.46795 115.775 4.52445C98.5718 -1.1024 80.049 -1.26769 62.7483 4.05125C45.4476 9.37019 30.2169 19.9127 19.1461 34.2321C8.07537 48.5514 1.70719 65.9459 0.915469 84.0284C0.123746 102.111 4.94728 119.995 14.7241 135.228C24.501 150.46 38.7521 162.293 55.5217 169.104C72.2913 175.914 90.7577 177.369 108.387 173.267C123.875 169.663 138.05 161.926 149.429 150.936C152.042 148.413 151.786 144.236 149.074 141.82C146.362 139.403 142.224 139.668 139.578 142.156C130.029 151.137 118.249 157.468 105.406 160.456C90.4214 163.943 74.725 162.707 60.4708 156.918C46.2166 151.129 34.1032 141.07 25.7929 128.123C17.4825 115.176 13.3825 99.9739 14.0555 84.6037C14.7285 69.2336 20.1414 54.4483 29.5516 42.2768C38.9617 30.1054 51.9078 21.1442 66.6134 16.6231C81.319 12.102 97.0634 12.2425 111.686 17.0254C124.218 21.1245 135.4 28.4616 144.128 38.2425C146.546 40.9526 150.645 41.5774 153.558 39.4075Z" />
      </mask>
      <path
        d="M153.558 39.4075C156.47 37.2376 157.09 33.0987 154.708 30.3569C144.333 18.4142 130.889 9.46795 115.775 4.52445C98.5718 -1.1024 80.049 -1.26769 62.7483 4.05125C45.4476 9.37019 30.2169 19.9127 19.1461 34.2321C8.07537 48.5514 1.70719 65.9459 0.915469 84.0284C0.123746 102.111 4.94728 119.995 14.7241 135.228C24.501 150.46 38.7521 162.293 55.5217 169.104C72.2913 175.914 90.7577 177.369 108.387 173.267C123.875 169.663 138.05 161.926 149.429 150.936C152.042 148.413 151.786 144.236 149.074 141.82C146.362 139.403 142.224 139.668 139.578 142.156C130.029 151.137 118.249 157.468 105.406 160.456C90.4214 163.943 74.725 162.707 60.4708 156.918C46.2166 151.129 34.1032 141.07 25.7929 128.123C17.4825 115.176 13.3825 99.9739 14.0555 84.6037C14.7285 69.2336 20.1414 54.4483 29.5516 42.2768C38.9617 30.1054 51.9078 21.1442 66.6134 16.6231C81.319 12.102 97.0634 12.2425 111.686 17.0254C124.218 21.1245 135.4 28.4616 144.128 38.2425C146.546 40.9526 150.645 41.5774 153.558 39.4075Z"
        stroke="#EB5757"
        strokeWidth="50%"
        mask="url(#path-1-inside-1)"
      />
      <mask id="path-2-inside-2" fill="white">
        <path d="M156.981 44.3787C160.047 42.4314 164.135 43.328 165.847 46.5312C173.101 60.1028 176.674 75.383 176.149 90.8399C175.625 106.297 171.023 121.299 162.865 134.348C160.939 137.427 156.8 138.044 153.873 135.894C150.947 133.743 150.345 129.64 152.23 126.535C158.841 115.642 162.569 103.201 163.004 90.3935C163.439 77.5856 160.564 64.9204 154.707 53.6042C153.037 50.3787 153.915 46.326 156.981 44.3787Z" />
      </mask>
      <path
        d="M156.981 44.3787C160.047 42.4314 164.135 43.328 165.847 46.5312C173.101 60.1028 176.674 75.383 176.149 90.8399C175.625 106.297 171.023 121.299 162.865 134.348C160.939 137.427 156.8 138.044 153.873 135.894C150.947 133.743 150.345 129.64 152.23 126.535C158.841 115.642 162.569 103.201 163.004 90.3935C163.439 77.5856 160.564 64.9204 154.707 53.6042C153.037 50.3787 153.915 46.326 156.981 44.3787Z"
        stroke="#3EA584"
        strokeWidth="40"
        mask="url(#path-2-inside-2)"
      />
    </svg>
  );
};

export default CircularChart;
