import React, { useEffect, useState } from "react";
import Copy from "components/Copy";
import { useTheme } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import { getSearchPlaceholder } from "helpers/func";
import { useStyles } from "styles/partnersPageStyles";
import TableLayout from "components/layouts/TableLayout";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getProviders } from "components/graphQL/useQuery";
import { CustomButton, Loader } from "components/Utilities";
import CompoundSearch from "components/Forms/CompoundSearch";
import AddProviderModal from "components/Forms/AddProviderModal";
import { searchOptions, defaultPageInfo } from "helpers/mockData";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { EnhancedTable, EmptyTable, NoData } from "components/layouts";
import { regenerateProviderProfileUrl } from "components/graphQL/Mutation";
import { hospitalTableHeadCells10 } from "components/Utilities/tableHeaders";
import { Grid, Typography, Button, TableCell, TableRow } from "@mui/material";
import {
  changeTableLimit,
  handlePageChange,
  trucateProfileLink,
} from "helpers/filterHelperFunctions";

const HMOEnrolle = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams();
  const [ids, setIds] = useState("");
  const [hospitals, setHospitals] = useState([]);
  const [openAddHcp, setOpenAddHcp] = useState(false);
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const [regenerate, { loading: load }] = useMutation(
    regenerateProviderProfileUrl
  );
  const [fetchHospitals, { loading, data, error, variables }] = useLazyQuery(
    getProviders,
    {
      variables: { userTypeId: id },
    }
  );

  const darkButtonType = {
    background: theme.palette.primary.main,
    hover: theme.palette.primary.light,
    active: theme.palette.primary.dark,
  };

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };

  useEffect(() => {
    fetchHospitals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (data) {
        setHospitals(data?.getProviders?.provider || []);
        setPageInfo(data?.getProviders?.pageInfo || defaultPageInfo);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [data]);

  const handleGenerateLink = (id) => {
    setIds(id);
    regenerate({
      variables: {
        id,
      },
      refetchQueries: [
        getProviders,
        {
          variables: {
            userTypeId: id,
          },
        },
      ],
    });
  };


  return (
    <>
      <Grid item flex={1} container direction="column" rowGap={2}>
        <Grid item container justifyContent="flex-end">
          <CustomButton
            endIcon={<PersonAddAlt1Icon />}
            title="Add Provider"
            type={darkButtonType}
            onClick={() => setOpenAddHcp(true)}
          />
        </Grid>
        <TableLayout
          search={
            <CompoundSearch
              queryParams={{
                fetchData: fetchHospitals,
                variables,
                loading,
                newVariables: {},
              }}
              setPageInfo={(data) =>
                setPageInfo(data?.profiles?.pageInfo || {})
              }
              setProfiles={(data) => setHospitals(data?.profiles?.data || [])}
              getSearchPlaceholder={(filterBy) =>
                getSearchPlaceholder(filterBy)
              }
              filterOptions={searchOptions}
            />
          }
        >
          {loading ? (
            <Loader />
          ) : error ? (
            <NoData error={error} />
          ) : hospitals.length > 0 ? (
            /* ================= HMO TABLE ================= */
            <Grid
              container
              item
              direction="column"
              overflow="hidden"
              maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
            >
              <EnhancedTable
                headCells={hospitalTableHeadCells10}
                rows={hospitals}
                paginationLabel="Providers per page"
                hasCheckbox={false}
                changeLimit={async (e) => {
                  changeTableLimit(fetchHospitals, {
                    first: e,
                  });
                }}
                dataPageInfo={pageInfo}
                handlePagination={async (page) => {
                  handlePageChange(fetchHospitals, page, pageInfo, {});
                }}
              >
                {hospitals.map((row) => {
                  const {
                    _id,
                    userCount,
                    profileUrl,
                    partnerCount,
                    enrolleeCount,
                    name,
                    doctorCount,
                  } = row;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={_id}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ maxWidth: "20rem" }}
                      >
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "left",
                          }}
                        >
                          <span style={{ fontSize: "1.25rem" }}>{name}</span>
                        </div>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <Link
                          to={`/user-type/hmo/${id}/${_id}/doctors`}
                          className={classes.link}
                        >
                          <Typography
                            variant="h3"
                            classes={{ root: classes.title }}
                          >
                            {doctorCount ? doctorCount : 0}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <Link
                          to={`/user-type/hmo/${id}/${_id}/users`}
                          className={classes.link}
                        >
                          <Typography
                            variant="h3"
                            classes={{ root: classes.title }}
                          >
                            {userCount ? userCount : 0}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <Link
                          to={`/user-type/hmo/${id}/${_id}/enrollee`}
                          className={classes.link}
                        >
                          <Typography
                            variant="h3"
                            classes={{ root: classes.title }}
                          >
                            {enrolleeCount ? enrolleeCount : 0}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <Link
                          to={`/user-type/hmo/${id}/${_id}/partners`}
                          className={classes.link}
                        >
                          <Typography
                            variant="h3"
                            classes={{ root: classes.title }}
                          >
                            {partnerCount === 0
                              ? partnerCount
                              : partnerCount > 0
                              ? partnerCount
                              : 0}
                          </Typography>
                        </Link>
                      </TableCell>

                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ maxWidth: "20rem" }}
                      >
                        {profileUrl ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <Typography
                              variant="h3"
                              classes={{ root: classes.title }}
                            >
                              {trucateProfileLink(profileUrl, 20)}
                            </Typography>
                            <Copy name="Profile Link" text={profileUrl} />
                          </div>
                        ) : (load && ids === _id) || loading ? (
                          <Grid item container justifyContent="left">
                            <Loader />
                          </Grid>
                        ) : (
                          <div style={{ width: "50%" }}>
                            <Button
                              variant="contained"
                              disableRipple
                              sx={{ width: "50%" }}
                              className={`${classes.tableBtn} ${classes.redBtn}`}
                              onClick={() => handleGenerateLink(_id)}
                            >
                              Generate Link
                            </Button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </EnhancedTable>
            </Grid>
          ) : (
            <EmptyTable
              headCells={hospitalTableHeadCells10}
              paginationLabel="Providers per page"
            />
          )}
        </TableLayout>
      </Grid>

      <AddProviderModal
        openAddHcp={openAddHcp}
        handleClose={() => setOpenAddHcp(false)}
        buttonType={buttonType}
        id={id}
        pushTo={`/user-type/hmo/${id}`}
      />
    </>
  );
};

export default HMOEnrolle;
