import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useStyles } from "styles/singleConsultationPage";

export const CaseNoteContainer = ({ children }) => {
    const classes = useStyles();
    return (
      <Grid
        item
        flexWrap="wrap"
        display="grid"
        gridTemplateColumns={{
          lg: "1fr 1fr 1fr",
          md: "1fr 1fr",
          sm: "1fr",
        }}
        className={classes.item}
        alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
        rowGap={{ xs: "1.5rem", sm: "1.5rem", lg: "3rem" }}
        columnGap={{ xs: "1.5rem", sm: "1.5rem" }}
      >
        {children}
      </Grid>
    );
  };

  CaseNoteContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };