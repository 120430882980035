import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "styles/singleConsultationPage";

export const ConsultationInfo = ({ header, item }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Grid container className={classes.subItem} gap={1}>
        <Grid item>
          <Typography className={classes.title}>{header}</Typography>
        </Grid>
        <Grid item>{item}</Grid>
      </Grid>
    </Grid>
  );
};

ConsultationInfo.propTypes = {
  header: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
};
