import React, { useEffect, useState } from "react";
import Copy from "components/Copy";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@apollo/client";
import { NoData } from "components/layouts";
import { secondsToMinutes } from "date-fns";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { dateMoment } from "components/Utilities/Time";
import { getConsult } from "components/graphQL/useQuery";
import { Loader, CustomButton } from "components/Utilities";
import { trucateString } from "helpers/filterHelperFunctions";
import { Grid, Chip, Avatar, Typography, Divider } from "@mui/material";
import { PrescriptionModal } from "components/modals/PrescriptionModal";

const useStyles = makeStyles((theme) => ({
  parentGridWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    boxShadow: "0px 0px 5px -1px rgba(0,0,0,0.1)",

    "&:not(:last-of-type)": {
      marginBottom: "5rem",
    },
  },

  infoBadge: {
    "&.MuiChip-root": {
      fontSize: "1.5rem",
      borderRadius: "1.5rem",
    },
  },

  title: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
      // marginRight: "2rem",
      whitespace: "wrap",
    },
  },
  item: {
    padding: "2rem 3rem",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "nowrap",
    // justifyContent: "space-between",
    "&.MuiGrid-root > *": {
      flex: 1,
    },
  },
  subItem: {
    "&.MuiGrid-container": {
      flexDirection: "column",
      wordBreak: "break-word",
    },
  },
}));

const HcpCaseNotes = () => {
  const classes = useStyles();
  const theme = useTheme();
  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
    disabled: theme.palette.common.black,
  };

  const { rowId } = useParams();

  const [consult, setConsult] = useState([]);

  const { loading, data, error } = useQuery(getConsult, {
    variables: {
      id: rowId,
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (data) {
      setConsult(data?.getConsultation);
    }
  }, [data, rowId]);
  if (error) return <NoData error={error} />;

  if (loading) return <Loader />;
  const {
    _id,
    prescription,
    type,
    consultationDuration,
    consultationOwner,
    referralId,
    createdAt,
    discomfortLevel,
    status,
    description,
    doctorNote,
    severity,
    firstNotice,
    contactMedium,
    updatedAt,
    patientData,
    symptoms,
  } = consult;

  return (
    <>
      <Grid container direction="column" gap={1} sx={{ margin: "3rem 0rem" }}>
        <Grid
          item
          container
          direction="column"
          className={classes.parentGridWrapper}
        >
          <Grid
            item
            container
            flexWrap="wrap"
            className={classes.item}
            flexDirection={{ xs: "column", sm: "row", md: "row" }}
            alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
            rowGap={{ xs: "1.5rem", sm: "1.5rem" }}
          >
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Consultation Date:
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography variant="h5">{dateMoment(createdAt)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Referral:
                  </Typography>
                </Grid>
                <Grid item>
                  {referralId ? (
                    <Grid item container gap={1}>
                      <Typography variant="body1">
                        {trucateString(referralId, 10, "front")}
                      </Typography>
                      <Copy text={referralId} name="Consultation ID" />
                    </Grid>
                  ) : (
                    "No value"
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                item
                container
                flexDirection="column"
                gap={1}
                className={classes.subItem}
              >
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Consultation ID:
                  </Typography>
                </Grid>
                <Grid item>
                  {_id ? (
                    <Grid item container gap={1}>
                      <Typography variant="h5">
                        {trucateString(_id, 10, "front")}
                      </Typography>
                      <Copy text={_id} name="Consultation ID" />
                    </Grid>
                  ) : (
                    <Typography variant="h5"> No value</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider color={theme.palette.common.lighterGrey} />
          <Grid
            item
            container
            className={classes.item}
            flexDirection={{ xs: "column", sm: "row", md: "row" }}
            alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
            rowGap={{ xs: "1.5rem", sm: "1.5rem" }}
          >
            <Grid item>
              <Grid container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Patient:
                  </Typography>
                </Grid>
                {patientData && Object.keys(patientData).length > 0 ? (
                  <Grid item container alignItems="center">
                    <Grid item marginRight={1}>
                      <Avatar
                        src={patientData.image}
                        alt={`Display photo of the ${patientData.firstName}`}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">{`${patientData.firstName} ${patientData.lastName}`}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="h5">No Doctor</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid item container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Contact:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {contactMedium ? contactMedium : "No Value"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Owner:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {consultationOwner ? consultationOwner : "No Value"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider color={theme.palette.common.lighterGrey} />

          <Grid
            item
            container
            className={classes.item}
            flexDirection={{ xs: "column", sm: "row", md: "row" }}
            alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
            rowGap={{ xs: "1.5rem", sm: "1.5rem" }}
          >
            <Grid item container className={classes.subItem} gap={1}>
              <Grid item>
                <Typography variant="body3" className={classes.title}>
                  Severity:
                </Typography>
              </Grid>
              <Grid item>
                <Grid container gap={1}>
                  <Typography variant="body1">
                    {severity ? severity : "No value"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container className={classes.subItem} gap={1}>
              <Grid item>
                <Typography variant="body3" className={classes.title}>
                  First Notice:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {firstNotice ? firstNotice : "No value"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container className={classes.subItem} gap={1}>
              <Grid item>
                <Typography variant="body3" className={classes.title}>
                  Discomfort:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {discomfortLevel ? discomfortLevel : "No Value"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Divider color={theme.palette.common.lighterGrey} />
          <Grid
            item
            container
            className={classes.item}
            flexDirection={{ xs: "column", sm: "row", md: "row" }}
            alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
            rowGap={{ xs: "1.5rem", sm: "1.5rem" }}
          >
            <Grid item container className={classes.subItem} gap={1}>
              <Grid item>
                <Typography variant="body3" className={classes.title}>
                  Symptoms:
                </Typography>
              </Grid>
              <Grid item>
                <Grid container gap={1}>
                  {symptoms ? (
                    symptoms.map((symptom, index) => {
                      const isLast = index === symptoms.length - 1;
                      return (
                        <Typography key={index} variant="body1">
                          {isLast ? `${symptom.name}` : `${symptom.name},`}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography variant="body1">No Value</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                item
                container
                flexDirection="column"
                className={classes.subItem}
                gap={1}
              >
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Status:
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    variant="contained"
                    label={status ? status : "No Value"}
                    className={classes.infoBadge}
                    style={{
                      background:
                        status === "completed" || status === "ongoing"
                          ? theme.palette.common.lightGreen
                          : theme.palette.common.lightRed,
                      color:
                        status === "completed" || status === "ongoing"
                          ? theme.palette.common.green
                          : theme.palette.common.red,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item container className={classes.subItem} gap={1}>
                <Grid item>
                  <Typography variant="body3" className={classes.title}>
                    Type:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {type ? type : "No Value"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider color={theme.palette.common.lighterGrey} />
          <Grid
            item
            container
            className={classes.item}
            flexDirection={{ xs: "column", sm: "row", md: "row" }}
            alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
            rowGap={{ xs: "1.5rem", sm: "1.5rem" }}
          >
            <Grid item container className={classes.subItem} gap={1}>
              <Grid item>
                <Typography variant="body3" className={classes.title}>
                  Description:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ lineHeight: 1.85 }}>
                  {description ? description : "No Value"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" gap={1}>
              <Grid item>
                <Typography variant="body3" className={classes.title}>
                  Doctors Note:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ lineHeight: 1.85 }}>
                  {doctorNote ? doctorNote : "No Value"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" gap={1}>
              <Grid item>
                <Typography variant="body3" className={classes.title}>
                  Updated At:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ lineHeight: 1.85 }}>
                  {dateMoment(updatedAt)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider color={theme.palette.common.lighterGrey} />

          <Grid
            item
            container
            className={classes.item}
            flexDirection={{ xs: "column", sm: "row", md: "row" }}
            alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
            rowGap={{ xs: "1.5rem", sm: "1.5rem" }}
          >
            <Grid item container className={classes.subItem} gap={1}>
              <Grid item>
                <Typography variant="body3" className={classes.title}>
                  Duration:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ lineHeight: 1.85 }}>
                  {consultationDuration
                    ? `${
                        Number(consultationDuration) > 60
                          ? `${secondsToMinutes(
                              Number(consultationDuration)
                            )} mins`
                          : `${consultationDuration} secs`
                      } `
                    : "No Value"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-end" alignItems="center">
              {prescription && (
                <Grid
                  item
                  container
                  width={{ md: "50%", xs: "100%", sm: "50%" }}
                >
                  <CustomButton
                    title="View Prescription"
                    width="100%"
                    type={buttonType}
                    onClick={handleDialogOpen}
                  />
                </Grid>
              )}
            </Grid>
            {/* <Grid item container direction="column" gap={1}></Grid>
            <Grid item container direction="column" gap={1}></Grid> */}
          </Grid>
        </Grid>
      </Grid>

      <PrescriptionModal
        isOpen={isOpen}
        handleDialogClose={handleDialogClose}
        consultationInfo={consult}
      />
    </>
  );
};

export default HcpCaseNotes;
