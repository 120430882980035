import React, { useEffect, useMemo, useState } from "react";
import Filter from ".";
import { isAfter } from "date-fns";
import PropTypes from "prop-types";
import useAlert from "hooks/useAlert";
import { useTheme } from "@mui/styles";
import { useQuery } from "@apollo/client";
import { CustomButton } from "components/Utilities";
import { removeEmptyStringValues } from "helpers/func";
import { getProviders } from "components/graphQL/useQuery";
import {
  doctorPayoutsFilterDefaultVal,
  doctorPayoutsStatusFilter
} from "helpers/mockData";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography
} from "@mui/material";

const dateInputStyle = {
  height: "50px",
  padding: "0rem 1rem",
  minWidth: "200px",
  borderRadius: "8px",
  border: "2px solid #E0E0E0",
  color: "#4F4F4F",
  fontWeight: "semibold"
};

const DoctorPayoutsFilters = ({
  filterState,
  setFilterState,
  onFilter,
  refetch
}) => {
  const theme = useTheme();
  const { displayAlert } = useAlert();
  const [providerState, setProviderState] = useState("");
  const [providers, setProviders] = useState([]);
  const { data, loading: loadingProviders } = useQuery(getProviders, {
    variables: { page: -1 }
  });

  const buttonType = useMemo(
    () => ({
      background: theme.palette.common.black,
      hover: theme.palette.primary.main,
      active: theme.palette.primary.dark
    }),
    [
      theme.palette.common.black,
      theme.palette.primary.dark,
      theme.palette.primary.main
    ]
  );

  const isFilterEmpty = useMemo(() => {
    const newObj = removeEmptyStringValues(filterState);
    if (Object.keys(newObj).length < 1) {
      return true;
    } else {
      return false;
    }
  }, [filterState]);

  useEffect(() => {
    if (data) {
      const providers = data.getProviders.provider;
      const options = providers?.map((provider) => {
        return {
          key: provider.name,
          value: provider._id
        };
      });
      setProviders([...options]);
    }
  }, [data]);

  return (
    <>
      <Grid item container flexWrap="wrap" spacing={4} alignItems="flex-end">
        {/* FILTER BY STATUS */}
        <Grid item>
          <Filter
            onHandleChange={(e) =>
              setFilterState((prev) => ({ ...prev, status: e?.target?.value }))
            }
            onClickClearBtn={() =>
              setFilterState((prev) => ({ ...prev, status: "" }))
            }
            options={[
              { key: "Status", value: "" },
              ...doctorPayoutsStatusFilter
            ]}
            name="doctor_payouts_status_filter"
            value={filterState.status}
            hasClearBtn={true}
            variant="small"
          />
        </Grid>

        {/* FILTER BY PROVIDER */}
        <Grid item>
          <Autocomplete
            value={providerState}
            id="providerId"
            name="providerId"
            loading={loadingProviders}
            sx={{ minWidth: "200px", height: "48px" }}
            onInputChange={(e) => {
              const value = e?.target?.value;
              if (!value || value === "") return;
              // setFilterState((prev) => ({ ...prev, provider: value }));
              // searchCompanies(value);
            }}
            onChange={(e, info) => {
              const value = info?.value;
              setProviderState(info?.key);
              setFilterState((prev) => ({ ...prev, provider: value }));
            }}
            options={providers}
            clearOnBlur={false}
            noOptionsText="Type to see options"
            // getOptionLabel={(option) => option.key}
            renderOption={(props, option) => <li {...props}>{option?.key}</li>}
            style={{ width: "100%", height: "50px" }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select provider" />
            )}
          />
        </Grid>

        {/* FILTER BY DATE */}
        <Grid item>
          <Grid container gap={2} sx={{ marginTop: "2rem" }}>
            <Grid item>
              <Typography sx={{ fontSize: "0.8em" }}>Start date</Typography>
              <input
                type="date"
                style={dateInputStyle}
                value={filterState?.startDate}
                onChange={(e) =>
                  setFilterState((prev) => ({
                    ...prev,
                    startDate: e?.target?.value
                  }))
                }
              />
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: "0.8em" }}>End date</Typography>
              <input
                type="date"
                style={dateInputStyle}
                value={filterState?.endDate}
                onChange={(e) =>
                  setFilterState((prev) => ({
                    ...prev,
                    endDate: e?.target?.value
                  }))
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ display: "flex" }} gap={2}>
          {isFilterEmpty ? (
            ""
          ) : (
            <Button
              onClick={() => {
                setFilterState(doctorPayoutsFilterDefaultVal);
                setProviderState("");
                refetch();
              }}
            >
              Clear
            </Button>
          )}
          <CustomButton
            title="Filter"
            width="100%"
            type={buttonType}
            onClick={() => {
              try {
                let isEndDateAfter;
                const { startDate, endDate } = filterState;
                if (startDate !== "" && endDate === "")
                  throw Error(
                    " Either start date or end date can not be empty"
                  );

                if (startDate === "" && endDate === "") {
                  isEndDateAfter = true;
                } else {
                  const parseDate = (date) => new Date(Date.parse(date));
                  isEndDateAfter = isAfter(
                    parseDate(endDate),
                    parseDate(startDate)
                  );
                }
                if (!isEndDateAfter)
                  throw Error("End date must be after Start date");
                onFilter();
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                displayAlert("error", error?.message);
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

DoctorPayoutsFilters.propTypes = {
  filterState: PropTypes.object.isRequired,
  setFilterState: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default DoctorPayoutsFilters;
