import { SvgIcon } from "@mui/material";
import React from "react";

function EditIcon(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M3.69332 13.0137C3.28666 13.0137 2.90666 12.8737 2.63332 12.6137C2.28666 12.287 2.11999 11.7937 2.17999 11.2604L2.42666 9.10036C2.47332 8.69369 2.71999 8.15369 3.00666 7.86036L8.47999 2.06702C9.84666 0.620358 11.2733 0.580358 12.72 1.94703C14.1667 3.31369 14.2067 4.74036 12.84 6.18703L7.36666 11.9804C7.08666 12.2804 6.56666 12.5604 6.15999 12.627L4.01332 12.9937C3.89999 13.0004 3.79999 13.0137 3.69332 13.0137ZM10.62 1.94036C10.1067 1.94036 9.65999 2.26036 9.20666 2.74036L3.73332 8.54036C3.59999 8.68036 3.44666 9.01369 3.41999 9.20703L3.17332 11.367C3.14666 11.587 3.19999 11.767 3.31999 11.8804C3.43999 11.9937 3.61999 12.0337 3.83999 12.0004L5.98666 11.6337C6.17999 11.6004 6.49999 11.427 6.63332 11.287L12.1067 5.49369C12.9333 4.61369 13.2333 3.80036 12.0267 2.66703C11.4933 2.15369 11.0333 1.94036 10.62 1.94036Z" />
      <path d="M11.56 7.29925C11.5466 7.29925 11.5266 7.29925 11.5133 7.29925C9.4333 7.09258 7.75996 5.51258 7.43996 3.44592C7.39996 3.17258 7.58663 2.91925 7.85996 2.87258C8.1333 2.83258 8.38663 3.01925 8.4333 3.29258C8.68663 4.90592 9.9933 6.14592 11.62 6.30592C11.8933 6.33258 12.0933 6.57925 12.0666 6.85258C12.0333 7.10592 11.8133 7.29925 11.56 7.29925Z" />
      <path d="M14 15.166H2C1.72667 15.166 1.5 14.9393 1.5 14.666C1.5 14.3927 1.72667 14.166 2 14.166H14C14.2733 14.166 14.5 14.3927 14.5 14.666C14.5 14.9393 14.2733 15.166 14 15.166Z" />
    </SvgIcon>
  );
}

export default EditIcon;
