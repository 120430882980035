import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import useAlert from "hooks/useAlert";
import { useTheme } from "@mui/styles";
import { getVariables } from "helpers/func";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CustomButton } from "components/Utilities";
import { Grid, TextField, Typography } from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import {
  disputeBtnText,
  disputeCautionText,
  disputeFormSuccessMsgs
} from "helpers/mockData";
import {
  cancelConsultation,
  createDispute,
  endConsultation,
  resendEmailSummary,
  resolveDispute
} from "components/graphQL/Mutation";

export const Form = ({
  type,
  loading,
  cautionText,
  btnText,
  onHandleSubmit,
  closeModal
}) => {
  const theme = useTheme();
  const reasonRef = useRef(null);

  const buttonType = useMemo(
    () => ({
      background: theme.palette.common.blue,
      hover: "#304984",
      active: "#304984",
      disabled: "#96AAD9"
    }),
    [theme.palette.common.blue]
  );

  const successBtn = useMemo(
    () => ({
      background: "#3EA584",
      hover: "#308066",
      active: "#308066",
      disabled: "#7BCEB3"
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const dangerBtn = useMemo(
    () => ({
      background: "#f32828",
      hover: "#dc0c0c",
      active: "#cc0b0b",
      disabled: "#f76868"
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Grid>
      {type === "confirm" ? (
        <>
          <Grid>
            <Grid sx={{ margin: "2rem 0rem" }}>
              <Typography textAlign={"center"}>{cautionText}</Typography>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              columnGap={2}
              sx={{ marginTop: "1rem" }}
            >
              <CustomButton
                title={"No"}
                type={dangerBtn}
                width="45%"
                onClick={() => {
                  closeModal();
                }}
              />
              <CustomButton
                title={btnText}
                type={successBtn}
                width="45%"
                onClick={() => {
                  onHandleSubmit("");
                }}
                isSubmitting={loading}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            wrap="no-wrap"
            gap={1}
            sx={{
              padding: "1rem",
              borderRadius: "8px",
              backgroundColor: "rgb(255,237,170, 0.3)",
              margin: "1rem 0rem"
            }}
          >
            <ReportGmailerrorredIcon
              sx={{ fill: "#ffd333", fontSize: "40px" }}
            />
            <Typography sx={{ fontSize: "0.9em" }}>{cautionText}</Typography>
          </Grid>
          <Grid container margin="3rem 0rem 1rem 0rem" gap={2}>
            <TextField
              inputRef={reasonRef}
              id="dispute"
              label="Reason"
              multiline
              variant="outlined"
              rows={4}
              fullWidth
            />
            <CustomButton
              title={btnText}
              type={buttonType}
              onClick={() => {
                const reason = reasonRef?.current?.value;
                if (!reason || reason === "")
                  throw Error(
                    "Please enter your reason in the field provided."
                  );

                onHandleSubmit(reason);
              }}
              isSubmitting={loading}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

Form.propTypes = {
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  cautionText: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  onHandleSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export const DisputeForm = ({ type, onSuccess, closeModal }) => {
  const { rowId } = useParams();
  const btnText = disputeBtnText[type];
  const cautionText = disputeCautionText[type];
  const { displayAlert, getErrorMsg } = useAlert();
  const [generateDispute, { loading: loadingCreateDispute }] =
    useMutation(createDispute);
  const [settleDispute, { loading: loadingResolveDispute }] =
    useMutation(resolveDispute);
  const [cancelConsult, { loading: loadingCancelConsultation }] =
    useMutation(cancelConsultation);
  const [completeConsultation, { loading: loadingCompleteConsultation }] =
    useMutation(endConsultation);
  const [resendEmail, { loading: loadingResendEmail }] =
    useMutation(resendEmailSummary);

  const isLoading =
    loadingCreateDispute ||
    loadingResolveDispute ||
    loadingCancelConsultation ||
    loadingCompleteConsultation ||
    loadingResendEmail;

  const formType =
    type === "completeConsultation" || type === "resendEmail" ? "confirm" : "";

  const endPoints = useMemo(
    () => ({
      create: generateDispute,
      resolve: settleDispute,
      cancel: settleDispute,
      completeConsultation: completeConsultation,
      cancelConsultation: cancelConsult,
      resendEmail: resendEmail
    }),
    [
      cancelConsult,
      completeConsultation,
      generateDispute,
      resendEmail,
      settleDispute
    ]
  );

  const summit = useCallback(
    async (variables = {}) => {
      try {
        await endPoints[type]({
          variables: { consultationId: rowId, ...variables }
        });
        displayAlert("success", disputeFormSuccessMsgs[type]);
        onSuccess();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        const errMsg = getErrorMsg(error);
        displayAlert("error", errMsg);
      }
    },
    [displayAlert, endPoints, getErrorMsg, onSuccess, rowId, type]
  );

  return (
    <Form
      type={formType}
      loading={isLoading}
      cautionText={cautionText}
      btnText={btnText}
      onHandleSubmit={(reason) => {
        summit({
          ...getVariables(reason)[type]
        });
      }}
      closeModal={() => closeModal()}
    />
  );
};

DisputeForm.propTypes = {
  type: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};
