import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  authError: {},
  healaID: null,
  id: localStorage.getItem("user_id"),
  userDetail: null,
  isAuthenticated: localStorage.getItem("dashboardAuth") ? true : false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      const data = action.payload.data?.login?.account || {};
      localStorage.setItem("dashboardAuth", true);
      localStorage.setItem("user_id", data._id);
      localStorage.setItem("token", data.access_token);

      state.loading = false;
      state.isAuthenticated = true;
      state.healaID = data.dociId;
      state.authError = data.messages;
      state.id = data._id;
    },
    refreshUser: (state) => {
      state.loading = false;
      state.isAuthenticated = true;
    },
    userDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    logout: (state) => {
      [
        "dashboardAuth",
        "user_id",
        "token",
        "email",
        "hcp",
        "role",
        "userTypeId",
        "dociId"
      ].forEach((item) => localStorage.removeItem(item));

      state.isAuthenticated = false;
      state.loading = false;
    }
  }
});

export const { loginSuccess, logout, userDetail, refreshUser } =
  authSlice.actions;
export default authSlice.reducer;
