import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { Loader } from "components/Utilities";
import { isSelected } from "helpers/isSelected";
import { useTheme } from "@mui/material/styles";
import { defaultPageInfo } from "helpers/mockData";
import TableLayout from "components/layouts/TableLayout";
import { Grid, TableRow, TableCell } from "@mui/material";
import { EditDelBtn } from "components/Buttons/EditDelBtn";
import { fetchMoreData } from "helpers/filterHelperFunctions";
import { CustomSearch } from "components/Forms/CompoundSearch";
import { getProvidersForPayout } from "components/graphQL/useQuery";
import { providersHeader } from "components/Utilities/tableHeaders";
import { NoData, EmptyTable, EnhancedTable } from "components/layouts";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    display: "grid",
    placeContent: "center",
    marginLeft: "1rem",
    background: theme.palette.common.lightGreen
  },
  button: {
    "&.css-1zf5oc-MuiButtonBase-root-MuiButton-root": {
      background: "#fff",
      color: theme.palette.common.grey,
      textTransform: "none",
      borderRadius: "2rem",
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      maxWidth: "10rem",

      "&:hover": {
        background: "#fcfcfc"
      },

      "&:active": {
        background: "#fafafa"
      },

      "& .css-9tj150-MuiButton-endIcon>*:nth-of-type(1)": {
        fontSize: "1.2rem"
      },

      "& .css-9tj150-MuiButton-endIcon": {
        marginLeft: ".3rem",
        marginTop: "-.2rem"
      }
    }
  },

  tableCell: {
    "&.MuiTableCell-root": {
      color: "rgb(0 0 0)",
      fontWeight: 400,
      fontSize: "1.25rem"
    }
  },

  badge: {
    "&.MuiChip-root": {
      fontSize: "1.3rem !important",
      height: "3rem",
      borderRadius: "1.3rem"
    }
  }
}));

const ProcessPayouts = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [providers, setProviders] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const selectedRows = useSelector((state) => state.table);
  const [fetchProvidersForPayout, { data, loading, error, variables }] =
    useLazyQuery(getProvidersForPayout, { variables: { page: -1 } });

  useEffect(() => {
    fetchProvidersForPayout();
  }, [fetchProvidersForPayout]);

  useEffect(() => {
    setPageInfo(data?.getPayoutsProviders?.pageInfo || defaultPageInfo);
    setProviders(data?.getPayoutsProviders?.provider || []);
  }, [data]);

  if (error) return <NoData error={error} />;

  return (
    <Grid container direction="column" rowSpacing={2}>
      <>
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ paddingBottom: "3rem" }}
        ></Grid>
        <TableLayout
          search={
            <CustomSearch
              queryParams={{
                fetchData: fetchProvidersForPayout,
                variables,
                newVariables: {}
              }}
              setData={() => null}
              searchBy="name"
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          }
        >
          {loading ? (
            <Loader />
          ) : providers.length > 0 ? (
            <Grid item container>
              <EnhancedTable
                headCells={providersHeader}
                rows={providers}
                paginationLabel="payout per page"
                hasCheckbox={false}
                hasPagination={false}
                dataPageInfo={pageInfo}
                handleChangePage={fetchMoreData}
                fetchData={fetchProvidersForPayout}
              >
                {providers.map((row, index) => {
                  const { _id, name, doctorCount } = row;
                  const isItemSelected = isSelected(_id, selectedRows);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={_id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                      >
                        {name}
                      </TableCell>

                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ color: theme.palette.common.red }}
                      >
                        {doctorCount}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <EditDelBtn
                            type="edit"
                            text="Process payouts"
                            noIcon={true}
                            LinkComponent={Link}
                            to={`/finance/process-payouts/${_id}`}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </EnhancedTable>
            </Grid>
          ) : (
            <EmptyTable
              headCells={providersHeader}
              paginationLabel="Providers  per page"
            />
          )}
        </TableLayout>
      </>
    </Grid>
  );
};

export default ProcessPayouts;
