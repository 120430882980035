import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Copy from "components/Copy";
import useAlert from "hooks/useAlert";
import { formatSecs } from "helpers/func";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { CaseNoteContainer } from "./CaseNoteContainer";
import StatusPill from "components/Utilities/StatusPill";
import { useStyles } from "styles/singleConsultationPage";
import { trucateString } from "helpers/filterHelperFunctions";
import { ConsultationInfo } from "components/ConsultationInfo";
import { dateMoment, timeMoment } from "components/Utilities/Time";
import { getEnrolleeProfileId } from "components/graphQL/useQuery";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { ViewConsultationSummary } from "components/modals/ViewConsultationSummary";

export const BasicConsultationInfoCard = ({ consultationInfo }) => {
  const classes = useStyles();
  const history = useHistory();
  const { displayAlert, getErrorMsg } = useAlert();
  const [openModal, setOpenModal] = useState(false);

  const {
    _id,
    type,
    consultationDuration,
    createdAt,
    status,
    contactMedium,
    isDisputed,
    disputeReason,
    disputeStatus,
    appointmentAcceptedAt,
    declineReason,
    appointmentStartedAt,
    reason,
    disputeResolvedReason,
    consultationOwner,
    followUpConsultationId,
    principalHmoId,
    providerId,
  } = consultationInfo;

  const isNotDeputed = useMemo(
    () => !isDisputed && disputeStatus === "pending",
    [disputeStatus, isDisputed]
  );

  const [getPrincipalProfileId, { loading }] = useLazyQuery(
    getEnrolleeProfileId,
    {
      variables: { hmoId: principalHmoId, providerId: providerId },
    }
  );

  const fetchPrincipalProfileId = async () => {
    try {
      if (!principalHmoId && !providerId) return;
      const res = await getPrincipalProfileId();
      const profilesArr = res.data?.profiles?.data || [];
      if (profilesArr?.length !== 0) {
        const profileId = profilesArr[0]?._id;
        history.push(`/patients/${profileId}`);
      } else {
        displayAlert("error", "Patients profile not found.");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("couldn't get enrollee profile ID", error);
      const errorMsg = getErrorMsg(error);
      displayAlert("error", errorMsg);
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      className={classes.parentGridWrapper}
    >
      <Grid container alignItems="center" marginBottom="3rem" gap={2}>
        <Typography
          variant="h3"
          sx={{
            textDecoration: "underline",
          }}
        >
          Basic information
        </Typography>
        {isDisputed && <StatusPill type="error" label="In dispute" />}
      </Grid>

      <CaseNoteContainer>
        {/* CONSULTATION ID */}
        <ConsultationInfo
          header="Consultation ID:"
          item={
            <>
              {_id ? (
                <Grid item container gap={1}>
                  <Typography className={classes.info}>
                    {trucateString(_id, 10, "front")}
                  </Typography>
                  <Copy text={_id} name="Consultation ID" />
                </Grid>
              ) : (
                <Typography className={classes.info}> No value</Typography>
              )}
            </>
          }
        />

        {/*  CONSULTATION DATE */}
        <ConsultationInfo
          header="Consultation Date:"
          item={
            <Typography className={classes.info}>
              {dateMoment(createdAt)}
            </Typography>
          }
        />

        {/* CONSULTATION TYPE */}
        <ConsultationInfo
          header="Consultation type:"
          item={<StatusPill type="normal" label={type} />}
        />

        {/* CONTACT MEDIUM */}
        <ConsultationInfo
          header="Contact medium:"
          item={
            <StatusPill
              label={contactMedium}
              size="small"
              onHandleClick={() => setOpenModal(true)}
            />
          }
        />

        {/*  CONSULTATION DURATION */}
        <ConsultationInfo
          header="Consultation duration:"
          item={
            <Typography className={classes.info}>
              {formatSecs(consultationDuration)}
            </Typography>
          }
        />

        {/*  CONSULTATION STATUS */}
        <ConsultationInfo
          header="Status:"
          item={
            <StatusPill
              label={status ? status : "No status"}
              type={
                status === "cancelled"
                  ? "error"
                  : status === "completed"
                  ? "success"
                  : status === "ongoing"
                  ? "normal"
                  : "unknown"
              }
            />
          }
        />

        {/* CONSULTATION OWNER */}
        <ConsultationInfo
          header="Consultation Owner:"
          item={consultationOwner}
        />

        {/* PRINCIPAL HMO ID */}
        {principalHmoId && (
          <ConsultationInfo
            header="Principal HMO ID:"
            item={
              <>
                <Grid item container gap={1} alignItems="center">
                  <Typography
                    className={classes.info}
                    onClick={(e) => {
                      e.stopPropagation();
                      fetchPrincipalProfileId();
                    }}
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    {principalHmoId}
                  </Typography>
                  {loading ? (
                    <CircularProgress size={15} color="primary" />
                  ) : (
                    <Copy text={principalHmoId} name="Principal ID" />
                  )}
                </Grid>
              </>
            }
          />
        )}

        {/* FOLLOW UP CONSULTATION ID */}
        {followUpConsultationId && (
          <ConsultationInfo
            header="Follow-up ID:"
            item={
              <>
                <Grid
                  item
                  container
                  gap={1}
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <Typography
                    className={classes.info}
                    onClick={() => {
                      history.push(
                        `/consultations/case-notes/${followUpConsultationId}`
                      );
                    }}
                  >
                    {trucateString(followUpConsultationId, 10, "front")}
                  </Typography>
                  <Copy
                    text={followUpConsultationId}
                    name="Follow-up consultation ID"
                  />
                </Grid>
              </>
            }
          />
        )}

        {/*  REASON FOR CANCELATION */}
        {status === "cancelled" && (
          <ConsultationInfo
            header="Reason for cancelation:"
            item={reason ? reason : "No reason."}
          />
        )}

        {!isNotDeputed && (
          <>
            {/*  DEPUTED STATUS */}
            <ConsultationInfo
              header="Dispute status:"
              item={
                <StatusPill
                  label={disputeStatus ? disputeStatus : "No status"}
                  type={
                    disputeStatus === "pending"
                      ? "warning"
                      : disputeStatus === "resolved"
                      ? "success"
                      : "normal"
                  }
                />
              }
            />

            {/*  DEPUTED REASON */}
            <ConsultationInfo
              header="Dispute reason:"
              item={
                <Typography className={classes.info}>
                  {disputeReason ? disputeReason : "No reason"}
                </Typography>
              }
            />

            {/* DEPUTE RESOLVE REASON */}
            {disputeStatus === "cancelled" ||
              (disputeStatus === "resolved" && (
                <ConsultationInfo
                  header="Dispute resolved reason:"
                  item={
                    <Typography className={classes.info}>
                      {disputeResolvedReason
                        ? disputeResolvedReason
                        : "No reason"}
                    </Typography>
                  }
                />
              ))}
          </>
        )}

        {/*  CONSULTATION ACCEPTED  AT */}
        {appointmentAcceptedAt && (
          <ConsultationInfo
            header="Doctor accepted at:"
            item={
              <Typography className={classes.info}>
                {timeMoment(Number(appointmentAcceptedAt))}
              </Typography>
            }
          />
        )}
        {/*  CONSULTATION STARTED AT  */}
        {appointmentStartedAt && (
          <ConsultationInfo
            header="Consultation started at:"
            item={
              <Typography className={classes.info}>
                {timeMoment(Number(appointmentStartedAt))}
              </Typography>
            }
          />
        )}

        {/*  DECLINE REASON */}
        {declineReason && (
          <ConsultationInfo
            header="Decline reason:"
            item={
              <Typography className={classes.info}>{declineReason}</Typography>
            }
          />
        )}
      </CaseNoteContainer>
      <ViewConsultationSummary
        isOpen={openModal}
        setIsOpen={setOpenModal}
        consultationInfo={consultationInfo}
      />
    </Grid>
  );
};

BasicConsultationInfoCard.propTypes = {
  consultationInfo: PropTypes.object.isRequired,
};
