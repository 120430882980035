import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAlert from "hooks/useAlert";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Loader } from "components/Utilities";
import { isSelected } from "helpers/isSelected";
import { useTheme } from "@mui/material/styles";
import { removeEmptyStringValues } from "helpers/func";
import TableLayout from "components/layouts/TableLayout";
import { useLazyQuery, NetworkStatus } from "@apollo/client";
import { getDoctorPayouts } from "components/graphQL/useQuery";
import { Grid, Chip, TableRow, TableCell } from "@mui/material";
import { payoutHeader } from "components/Utilities/tableHeaders";
import { timeMoment, dateMoment } from "components/Utilities/Time";
import { NoData, EmptyTable, EnhancedTable } from "components/layouts";
import DoctorPayoutsFilters from "components/Forms/Filters/DoctorPayoutFilters";
import {
  defaultPageInfo,
  doctorPayoutsFilterDefaultVal
} from "helpers/mockData";
import {
  changeTableLimit,
  deleteVar,
  fetchMoreData,
  filterData,
  handlePageChange
} from "helpers/filterHelperFunctions";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    display: "grid",
    placeContent: "center",
    marginLeft: "1rem",
    background: theme.palette.common.lightGreen
  },
  button: {
    "&.css-1zf5oc-MuiButtonBase-root-MuiButton-root": {
      background: "#fff",
      color: theme.palette.common.grey,
      textTransform: "none",
      borderRadius: "2rem",
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      maxWidth: "10rem",

      "&:hover": {
        background: "#fcfcfc"
      },

      "&:active": {
        background: "#fafafa"
      },

      "& .css-9tj150-MuiButton-endIcon>*:nth-of-type(1)": {
        fontSize: "1.2rem"
      },

      "& .css-9tj150-MuiButton-endIcon": {
        marginLeft: ".3rem",
        marginTop: "-.2rem"
      }
    }
  },

  tableCell: {
    "&.MuiTableCell-root": {
      color: "rgb(0 0 0)",
      fontWeight: 400,
      fontSize: "1.25rem"
    }
  },

  badge: {
    "&.MuiChip-root": {
      fontSize: "1.3rem !important",
      height: "3rem",
      borderRadius: "1.3rem"
    }
  }
}));

const Payout = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [payout, setPayout] = useState([]);
  const { displayAlert, getErrorMsg } = useAlert();
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const { selectedRows } = useSelector((state) => state.table);
  const [filterState, setFilterState] = useState(doctorPayoutsFilterDefaultVal);
  const [
    fetchPayout,
    { data, loading, error, refetch, variables, networkStatus }
  ] = useLazyQuery(getDoctorPayouts, { notifyOnNetworkStatusChange: true });

  const queryParams = useMemo(
    () => ({
      additionalVar: {
        orderBy: "-createdAt",
        first: pageInfo?.limit || 10
      },
      varToDelete: variables,
      fetchPayout,
      refetch
    }),
    [fetchPayout, pageInfo?.limit, refetch, variables]
  );

  const onHandleFilterChange = useCallback(
    async (filterValues) => {
      try {
        deleteVar(variables);
        const values = {
          ...queryParams.additionalVar,
          ...filterValues
        };
        const filterVariables = removeEmptyStringValues(values);

        const data = await filterData(filterVariables, {
          fetchData: fetchPayout,
          refetch: refetch,
          variables: variables
        });

        if (data) {
          setData(data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        const errMsg = getErrorMsg(error);
        displayAlert("error", errMsg);
        //refresh(setFilterState, "");
      }
    },
    [
      displayAlert,
      fetchPayout,
      getErrorMsg,
      queryParams.additionalVar,
      refetch,
      variables
    ]
  );

  const setData = (data) => {
    setPageInfo(data?.getPayouts?.pageInfo);
    setPayout(data?.getPayouts?.data);
  };

  useEffect(() => {
    fetchPayout({
      variables: { first: pageInfo?.limit, orderBy: "-createdAt" }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) setData(data);
  }, [data]);

  if (error) return <NoData error={error} />;

  return (
    <Grid container direction="column" rowSpacing={2}>
      <>
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ paddingBottom: "3rem" }}
        ></Grid>
        <TableLayout
          filters={
            <DoctorPayoutsFilters
              filterState={filterState}
              setFilterState={setFilterState}
              onFilter={() => {
                onHandleFilterChange(filterState);
              }}
              refetch={() =>
                fetchPayout({
                  variables: { first: pageInfo?.limit, orderBy: "-createdAt" }
                })
              }
            />
          }
        >
          {loading || NetworkStatus.refetch === networkStatus ? (
            <Loader />
          ) : payout.length > 0 ? (
            <Grid item container>
              <EnhancedTable
                headCells={payoutHeader}
                rows={payout}
                paginationLabel="payout per page"
                hasCheckbox={false}
                changeLimit={async (e) => {
                  const filterVar = removeEmptyStringValues(variables);
                  changeTableLimit(fetchPayout, {
                    ...filterVar,
                    first: e,
                    orderBy: "-createdAt",
                  });
                }}
                dataPageInfo={pageInfo}
                handlePagination={async (page) => {
                  const filterVar = removeEmptyStringValues(variables);
                  handlePageChange(fetchPayout, page, pageInfo, {
                    orderBy: "-createdAt",
                    ...filterVar
                  });
                }}
                fetchData={fetchPayout}
                handleChangePage={fetchMoreData}
              >
                {payout.map((row, index) => {
                  const { amount, createdAt, status, _id, doctorData } = row;
                  const { firstName, lastName } = doctorData;
                  const isItemSelected = isSelected(_id, selectedRows);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={_id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                      >
                        {row?.doctorData && row?.doctorData[0] !== "object" ? (
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "left"
                            }}
                          >
                            <span style={{ fontSize: "1.25rem" }}>{`${
                              firstName && firstName
                            } ${lastName}`}</span>
                          </div>
                        ) : (
                          "No Name"
                        )}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                      >
                        {amount}
                      </TableCell>

                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ color: theme.palette.common.red }}
                      >
                        <Chip
                          label={status}
                          className={classes.badge}
                          style={{
                            background:
                              status === "Success"
                                ? theme.palette.common.lightGreen
                                : status === "Failed"
                                ? theme.palette.common.lightGreen
                                : theme.palette.common.lightRed,
                            color:
                              status === "Success"
                                ? theme.palette.common.green
                                : status === "Failed"
                                ? theme.palette.common.danger
                                : theme.palette.common.red
                          }}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {`${dateMoment(createdAt)} - ${timeMoment(createdAt)}`}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </EnhancedTable>
            </Grid>
          ) : (
            <EmptyTable
              headCells={payoutHeader}
              paginationLabel="Payout  per page"
            />
          )}
        </TableLayout>
      </>
    </Grid>
  );
};

export default Payout;
