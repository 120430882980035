import { sortByDate } from "helpers/func";
import app from "../helpers/firebase.config";
import { getFirestore, getDocs, collection } from "firebase/firestore";

export const useFireStore = () => {
  const db = getFirestore(app);

  // Fetch all documents from a collection
  const fetchAllDocuments = async (roomId) => {
    try {
      let messages = [];
      const querySnapshot = await getDocs(
        collection(db, "Messages", roomId, roomId)
      );
      querySnapshot.forEach((doc) => {
        messages.push(doc.data());
      });
      return sortByDate(messages);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching documents: ", error);
    }
  };

  return {
    fetchAllDocuments,
  };
};
