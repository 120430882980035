import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid, Paper } from "@mui/material";

const useStyles = makeStyles(() => ({
  cont: {
    "&#table_layout": {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      padding: "30px 20px",
      width: "100%"
    }
  }
}));

const TableLayout = ({ children, filters, search, actions }) => {
  const classes = useStyles();
  return (
    <Paper id="table_layout" className={`${classes.cont}`} elevation={0}>
      <Grid
        container
        justifyContent="space-between"
        flexWrap={{sm: "nowrap"}}
        marginBottom="3rem"
        spacing={3}
      >
        <Grid item width="100%">{filters}</Grid>
        <Grid item width="100%">{search}</Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        flexWrap={"nowrap"}
        marginBottom="3rem"
        spacing={3}
      >
        <Grid item>
          <Grid container>{actions && actions}</Grid>
        </Grid>
      </Grid>
      {children}
    </Paper>
  );
};

TableLayout.propTypes = {
  children: PropTypes.node.isRequired,
  filters: PropTypes.node,
  search: PropTypes.node,
  actions: PropTypes.node
};

export default TableLayout;
