import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { removeAll } from "../store/slices/tableSlice";

const ClearStoreOnURLChange = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(removeAll());
  }, [dispatch, location.pathname]);

  return null;
};

export default ClearStoreOnURLChange;
