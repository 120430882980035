import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRows: {}
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    add: (state, action) => {
      state.selectedRows = { ...action.payload, ...state.selectedRows };
    },
    replace: (state, action) => {
      state.selectedRows = action.payload;
    },
    removeAll: (state) => {
      state.selectedRows = {};
    },
    removeRow: (state, action) => {
      const newState = Object.keys(state.selectedRows).filter(
        (row) => row !== action.payload
      );
      state.selectedRows = newState;
    }
  }
});

export const { add, removeAll, removeRow, replace } = tableSlice.actions;
export default tableSlice.reducer;
