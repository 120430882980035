import React, { useState, useEffect } from "react";
import { useLazyQuery, NetworkStatus } from "@apollo/client";
import useAlert from "hooks/useAlert";
import { useSelector } from "react-redux";
import { Loader } from "components/Utilities";
import { useHistory } from "react-router-dom";
import { isSelected } from "helpers/isSelected";
import { useTheme } from "@mui/material/styles";
import { getSearchPlaceholder } from "helpers/func";
import { useStyles } from "styles/patientsPageStyles";
import TableLayout from "components/layouts/TableLayout";
import CompoundSearch from "components/Forms/CompoundSearch";
import { defaultPageInfo, searchOptions } from "helpers/mockData";
import PatientFilters from "components/Forms/Filters/PatientFilters";
import { patientsHeadCells } from "components/Utilities/tableHeaders";
import { NoData, EmptyTable, EnhancedTable } from "components/layouts";
import { /* Checkbox, */ Grid, TableCell, TableRow } from "@mui/material";
import {
  getPatients,
  getPatientsByPlan,
  getPatientsByStatus,
} from "components/graphQL/useQuery";
import {
  changeTableLimit,
  handlePageChange,
} from "helpers/filterHelperFunctions";
// import { useTableActions } from "components/hooks/useTableActions";

const Patients = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { displayAlert } = useAlert();
  const [profiles, setProfiles] = useState([]);
  // const { selectSingleRow } = useTableActions();
  const { selectedRows } = useSelector((state) => state.table);
  const [fetchPatient, { loading, refetch, error, variables, networkStatus }] =
    useLazyQuery(getPatients);

  const [
    fetchPatientByStatus,
    {
      loading: byStatusLoading,
      variables: byStatusVaribles,
      refetch: byStatusRefetch,
    },
  ] = useLazyQuery(getPatientsByStatus);

  const [
    fetchPatientByPlan,
    {
      loading: byPlanLoading,
      variables: byPlanVaribles,
      refetch: byPlanRefetch,
    },
  ] = useLazyQuery(getPatientsByPlan);

  const [pageInfo, setPageInfo] = useState(defaultPageInfo);

  useEffect(() => {
    fetchPatient({
      variables: {
        first: pageInfo.limit,
      },
    })
      .then(({ data }) => {
        if (data) {
          setPageInfo(data?.profiles?.pageInfo || []);
          setProfiles(data?.profiles?.data || defaultPageInfo);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTableData = async (response, errMsg) => {
    response
      .then(({ data }) => {
        setPageInfo(data?.profiles?.pageInfo || defaultPageInfo);
        setProfiles(data?.profiles?.data || []);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        displayAlert("error", errMsg);
      });
  };

  if (error) return <NoData error={error} />;

  return (
    <Grid item flex={1} container direction="column" rowGap={2}>
      <Grid
        item
        container
        spacing={2}
        className={classes.searchFilterContainer}
      >
        <Grid item container flexWrap="wrap" spacing={4}></Grid>
      </Grid>
      <TableLayout
        filters={
          <PatientFilters
            setProfiles={setProfiles}
            setPageInfo={setPageInfo}
            queryParams={{
              patientsParams: { fetchPatient, loading, refetch, variables },
              patientsByStatusParams: {
                byStatusLoading,
                byStatusVaribles,
                byStatusRefetch,
                fetchPatientByStatus,
              },
              patientsByPlanParams: {
                byPlanLoading,
                byPlanVaribles,
                byPlanRefetch,
                fetchPatientByPlan,
              },
            }}
          />
        }
        search={
          <CompoundSearch
            queryParams={{
              fetchData: fetchPatient,
              variables,
              loading,
              newVariables: {},
            }}
            setPageInfo={(data) => setPageInfo(data?.profiles?.pageInfo || {})}
            setProfiles={(data) => setProfiles(data?.profiles?.data || [])}
            getSearchPlaceholder={(filterBy) => getSearchPlaceholder(filterBy)}
            filterOptions={searchOptions}
          />
        }
      >
        {loading || byStatusLoading || byPlanLoading ? (
          <Loader />
        ) : networkStatus === NetworkStatus.refetch ? (
          <Loader />
        ) : profiles.length > 0 ? (
          /* ================= PATIENTS TABLE ================= */
          <Grid
            container
            item
            direction="column"
            overflow="hidden"
            maxWidth={{ md: "100%", sm: "100%", xs: "100%" }}
          >
            <EnhancedTable
              headCells={patientsHeadCells}
              rows={profiles}
              paginationLabel="Patients per page"
              hasCheckbox={false}
              changeLimit={async (e) => {
                const res = changeTableLimit(fetchPatient, {
                  ...variables,
                  first: e,
                });
                await setTableData(res, "Failed to change table limit.");
              }}
              dataPageInfo={pageInfo}
              handlePagination={async (page) => {
                const res = handlePageChange(fetchPatient, page, pageInfo, {
                  ...variables,
                });
                await setTableData(res, "Failed to change page.");
              }}
            >
              {profiles.map((row, index) => {
                const {
                  _id,
                  dociId,
                  firstName,
                  lastName,
                  email,
                  subscriptionId,
                  providerId,
                  consultations,
                } = row;

                const labelId = `enhanced-table-checkbox-${index}`;
                const isItemSelected = isSelected(_id, selectedRows);

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={_id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`patients/${_id}`);
                    }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          selectSingleRow(e.target.checked, _id, profiles);
                        }}
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId
                        }}
                      />
                    </TableCell> */}
                    <TableCell
                      id={labelId}
                      scope="row"
                      align="left"
                      className={classes.tableCell}
                      style={{
                        color: theme.palette.common.grey,
                        textAlign: "left",
                      }}
                    >
                      {dociId?.split("-")[1]}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "left",
                        }}
                      >
                        <span style={{ fontSize: "1.25rem" }}>{`${
                          firstName && firstName
                        } ${lastName && lastName}`}</span>
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "left",
                        }}
                      >
                        <span
                          style={{ fontSize: "1.25rem" }}
                        >{` ${email}`}</span>
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {subscriptionId?.planId?.name || "No Plan"}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {providerId ? providerId?.name : "No Provider"}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {consultations ? consultations : 0}
                    </TableCell>
                    {/* <TableCell align="left" className={classes.tableCell}>
        <Chip
          label={status && status === "Active" ? "Active" : "Inactive"}
          className={classes.badge}
          style={{
            background:
              status === "Active"
                ? theme.palette.common.lightGreen
                : theme.palette.common.lightRed,
            color:
              status === "Active"
                ? theme.palette.common.green
                : theme.palette.common.red,
          }}
        />
      </TableCell> */}
                  </TableRow>
                  // <PatientsRow
                  //   key={index}
                  //   patientData={row}
                  //   labelId={labelId}
                  // />
                );
              })}
            </EnhancedTable>
          </Grid>
        ) : (
          <EmptyTable
            headCells={patientsHeadCells}
            paginationLabel="Patients per page"
          />
        )}
      </TableLayout>
    </Grid>
  );
};

export default Patients;
