import React, { useState } from "react";
import {
  Grid,
  IconButton,
  TableRow,
  TableCell,
  Card,
  InputBase,
  MenuItem,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import File2 from "components/validation/File2";
import { Formik, Form } from "formik";
import { makeStyles } from "@mui/styles";
import { CustomButton } from "components/Utilities";
import { ReactComponent as DangerIcon } from "assets/images/Vector.svg";
import { ReactComponent as SearchIcon } from "assets/images/searchs.svg";
import TableLayout from "components/layouts/TableLayout";
import { medicalHeadCells } from "components/Utilities/tableHeaders";
import { EmptyTable, EnhancedTable } from "components/layouts";
import { CustomSelect } from "components/validation/Select";
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    "&.MuiGrid-item": {
      outline: "2px solid #fff",
      padding: "2rem",
      background: "#fff",
      display: "flex",
      gap: "2rem",
    },
  },
  tableCell: {
    "&.MuiTableCell-root": {
      color: "rgb(0 0 0)",
      fontWeight: 400,
      fontSize: "1.25rem",
      textAlign: "left",
    },
  },
  typo: {
    "&.MuiTypography-root": {
      color: "#979797",
      fontWeight: 500,
      fontSize: "3.2rem",
      textAlign: "center",
      width: "100%",
    },
  },
}));
const LaboratoryUpload = () => {
  const classes = useStyles();
  const [state, setState] = useState([]);
  const [medical] = useState(
    Array(10).fill({
      a: " Emzor paracetamol",
      b: " 20 tablets per card x 4",
      c: "N200.00",
    })
  );
  const [formState, setFormState] = useState();
  const [menuState, setMenuState] = useState(false);
  const [value, setValue] = useState("");
  const [input, setInput] = useState("");
  const [inputState, setInputState] = useState();
  const options = [
    { key: "Minutes", value: "Minutes" },
    { key: "Hours", value: "Hours" },
    { key: "Days", value: "Days" },
  ];
  const handleChange = (e) => {
    const { value } = e.target;
    setInputState(value);
    setInput(value.toLowerCase());
  };
  const handleSelect = (item) => {
    const { name, _id } = item;

    setFormState({
      hmo: name,
      userTypeId: _id,
    });
    setState([]);
    setInputState(name);
  };
  const bg = !menuState ? "#3E5EA9" : "#ECEFF6";
  const bg2 = menuState ? "#3E5EA9" : "#ECEFF6";
  const textColor = !menuState ? "#3E5EA9" : "#fff";
  const textColor2 = menuState ? "#3E5EA9" : "#fff";
  const buttonType = {
    background: bg,
    hover: bg,
    active: bg,
  };
  const buttonTypes = {
    background: bg2,
    hover: bg2,
    active: bg2,
  };
  const editTestValidation = Yup.object({
    upload: Yup.string("Upload a single Image")
      .typeError("Must be an Image ,png,jpg")
      .required("Image is required"),
  });
  return (
    <Grid container>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 4, mt: 4 }}
      >
        <Grid item className={classes.buttonContainer}>
          <CustomButton
            title="Upload Doc"
            textColor={textColor2}
            type={buttonType}
            width="27rem"
            onClick={() => setMenuState(false)}
          />
          <CustomButton
            title="View Partners"
            textColor={textColor}
            type={buttonTypes}
            width="27rem"
            onClick={() => setMenuState(true)}
          />
        </Grid>
      </Grid>
      {menuState ? (
        <Grid item container gap={4} sx={{ mt: "5rem" }}>
          <Grid
            item
            gap={4}
            container
            flexWrap="nowrap"
            alignItems="center"
            sx={{ position: "relative" }}
          >
            <Grid item flexWrap="nowrap" sx={{ height: "6rem", flex: 1 }}>
              <Grid
                item
                sx={{
                  height: "inherit",
                  width: "100%",
                  color: "#E6E6E6",
                  background: "rgba(230, 230, 230, 0.5)",
                  borderRadius: "4rem",
                  display: "flex",
                  alignItems: "center",
                  padding: "1.6rem !important",
                  paddingTop: 0,
                  fontWeight: 300,
                  fontSize: "1.6rem",
                  letterSpacing: "-0.01em",
                }}
              >
                <IconButton
                  type="button"
                  aria-label="search"
                  sx={{ color: "#6C6C6C", fontSize: "small" }}
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ flex: 1, p: 0, font: "inherit" }}
                  size="large"
                  placeholder="Search for drug by name"
                  onChange={handleChange}
                  value={inputState}
                />
              </Grid>
            </Grid>
            {state.length > 0 && input !== "" && (
              <Card
                item
                xs={4}
                sx={{
                  mt: 2,
                  position: "absolute",
                  zIndex: 300,
                  maxHeight: "20rem",
                  background: "white",
                  padding: "0",
                  width: "100%",
                  overflowY: "scroll",
                  boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
                }}
              >
                {state?.map((item) => (
                  <MenuItem
                    key={item.name}
                    sx={{
                      cursor: "pointer",
                      display: "block",
                      fontSize: "1.8rem",
                    }}
                    onClick={() => handleSelect(item)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Card>
            )}
            <Grid item>
              <CustomButton
                title="Search"
                textColor="#fff"
                height="6.2rem"
                type={buttonTypes}
                width="20rem"
                borderRadius="8.2rem"
              />
            </Grid>
          </Grid>
          {/* <Grid container> */}
          <TableLayout>
            {medical?.length > 0 ? (
              <Grid container>
                <EnhancedTable
                  headCells={medicalHeadCells}
                  rows={medical}
                  paginationLabel="Doctors per page"
                  hasCheckbox={false}
                  //   changeLimit={async (e) => {
                  //     const res = changeTableLimit(fetchDoctors, {
                  //       first: e,
                  //     });
                  //     await setTableData(res, "Failed to change table limit");
                  //   }}
                  //   dataPageInfo={pageInfo}
                  //   handlePagination={async (page) => {
                  //     const res = handlePageChange(fetchDoctors, page, pageInfo, {});
                  //     await setTableData(res, "Failed to change page.");
                  //   }}
                >
                  {medical.map((row, index) => {
                    // const { _id, patientData, tests, scheduledAt, testId } = row;
                    // const isItemSelected = isSelected(_id, selectedRows);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const x = tests?.map((i) => i.price);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer", width: "100%" }}
                        // onClick={() => history.push(`/schedule/${_id}/schedule`)}
                        // selected={isItemSelected}
                      >
                        <TableCell
                          id={labelId}
                          scope="row"
                          align="left"
                          className={classes.tableCell}
                        >
                          {row.a}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row.b}
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>
                          {row.c}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </EnhancedTable>
              </Grid>
            ) : (
              <EmptyTable
                headCells={medicalHeadCells}
                paginationLabel="upload  per page"
                text="No Medication Upload"
              />
            )}
          </TableLayout>

          <Typography variant="h4" sx={{ my: 2 }} className={classes.typo}>
            Need to update entire document? {/* eslint-disable-next-line */}
            <a
              href="#"
              rel="noreferrer"
              style={{ color: "#3E5EA9", textDecoration: "none" }}
            >
              Re-upload
            </a>
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={8} sx={{ margin: "auto" }}>
          <Grid container gap={4} flexDirection="column">
            <Grid
              container
              flexWrap="nowrap"
              alignItems="center"
              gap={4}
              sx={{
                py: 2,
                px: 6,
                color: "#3E5EA9",
                background: "#ECEFF6",
                fontWeight: 300,
                fontSize: "2rem",
                lineHeight: "2.5rem",
                textAlign: "center",
                height: "9.8rem",
              }}
            >
              <Grid item>
                <DangerIcon sx={{ font: "inherit" }} />
              </Grid>
              <Typography variant="body2" sx={{ font: "inherit" }}>
                Search for hospital partner, then upload excel documents of
                medications available. Drag excel document here or upload from
                computer.
              </Typography>
            </Grid>
            <Grid
              item
              gap={4}
              container
              flexWrap="nowrap"
              alignItems="center"
              sx={{ position: "relative" }}
            >
              <Grid item flexWrap="nowrap" sx={{ height: "6rem", flex: 1 }}>
                <Grid
                  item
                  sx={{
                    height: "inherit",
                    width: "100%",
                    color: "#E6E6E6",
                    background: "#fff",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid #E6E6E6",
                    padding: "1.6rem !important",
                    paddingTop: 0,
                    fontWeight: 300,
                    fontSize: "1.6rem",
                    letterSpacing: "-0.01em",
                  }}
                >
                  <IconButton
                    type="button"
                    aria-label="search"
                    sx={{ color: "#6C6C6C", fontSize: "small" }}
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ flex: 1, p: 0, font: "inherit" }}
                    size="large"
                    placeholder="Search Hospital"
                    onChange={handleChange}
                    value={inputState}
                  />
                </Grid>
              </Grid>
              {state.length > 0 && input !== "" && (
                <Card
                  item
                  xs={4}
                  sx={{
                    mt: 2,
                    position: "absolute",
                    zIndex: 300,
                    maxHeight: "20rem",
                    background: "white",
                    padding: "0",
                    width: "100%",
                    overflowY: "scroll",
                    boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
                  }}
                >
                  {state?.map((item) => (
                    <MenuItem
                      key={item.name}
                      sx={{
                        cursor: "pointer",
                        display: "block",
                        fontSize: "1.8rem",
                      }}
                      onClick={() => handleSelect(item)}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Card>
              )}
            </Grid>

            <Formik
              initialValues={{
                upload: "",
              }}
              onSubmit={{}}
              validationSchema={editTestValidation}
              validateOnChange={false}
              validateOnMount={false}
              validateOnBlur={false}
            >
              {({ isSubmitting }) => {
                return (
                  <Form style={{ marginTop: "1rem" }}>
                    {/* <Grid
                      item
                      container
                      // direction="column"
                      gap={1}
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height: "35rem",
                        background: "#fff",
                        border: "4px dashed #979797",
                        borderRadius: "1.2rem",
                      }}
                    > */}
                    <File2
                      control="files"
                      label="Drag and drop document or upload from your computer"
                      id="logo"
                      name="upload"
                    />
                    {/* </Grid> */}
                  </Form>
                );
              }}
            </Formik>

            {/* <Grid
              item
              container
              sx={{
                height: "35rem",
                background: "#fff",
                border: "4px dashed #979797",
              }}
            ></Grid> */}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default LaboratoryUpload;
