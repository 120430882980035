import React, { useEffect, useState } from "react";
import Filter from ".";
import { isAfter } from "date-fns";
import PropTypes from "prop-types";
import useAlert from "hooks/useAlert";
import { useTheme } from "@mui/styles";
import { useQuery } from "@apollo/client";
import { CustomButton } from "components/Utilities";
import { getProviders } from "components/graphQL/useQuery";
import { useAutoCompleteStyles } from "styles/componentStyles";
import { deleteVar, filterData } from "helpers/filterHelperFunctions";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { calculateFilterDate, removeEmptyStringValues } from "helpers/func";
import {
  consultationContactModes,
  consultationDates,
  consultationFilterDefaultVal,
  consultationStatus,
  consultationTypes
} from "helpers/mockData";

const filterEnum = Object.freeze({
  STATUS: "status",
  DATE: "date",
  TYPE: "type",
  CONTACT: "contact",
  PROVIDERID: "providerId"
});

const dateInputStyle = {
  height: "50px",
  padding: "0rem 1rem",
  minWidth: "200px",
  borderRadius: "8px",
  border: "2px solid #E0E0E0",
  color: "#4F4F4F",
  fontWeight: "semibold"
};

const ConsultationsFilters = ({
  filterState,
  setFilterState,
  queryParams,
  disabled
}) => {
  const theme = useTheme();
  const [providers, setProviders] = useState([]);
  const { displayAlert, getErrorMsg } = useAlert();
  const [dateFilter, setDateFilter] = useState("");
  const classes = useAutoCompleteStyles({ height: "48px" });
  const { varToDelete, fetchConsultations, refetch, additionalVar } =
    queryParams;
  const { data, loading } = useQuery(getProviders, { variables: { page: -1 } });

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark
  };

  const onFilterChange = async (newFilterValue) => {
    try {
      deleteVar(varToDelete);
      const values = {
        ...filterState,
        ...additionalVar,
        ...newFilterValue
      };

      const filterValues = removeEmptyStringValues(values);
      await filterData(filterValues, {
        fetchData: fetchConsultations,
        refetch
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
      refresh(setFilterState, consultationFilterDefaultVal);
    }
  };

  const refresh = async (setFilterValue, defaultVal) => {
    try {
      displayAlert("error", "Something went wrong while filtering. Try again.");
      setFilterValue(defaultVal);
      deleteVar(varToDelete);

      refetch({ variables: { ...additionalVar } });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      displayAlert("error", "Failed to get HMO enrollees data, Try again");
    }
  };

  const filter = (name, value) => {
    setFilterState((prev) => ({ ...prev, [name]: value }));
    onFilterChange({ [name]: value });
  };

  useEffect(() => {
    const providers = (data?.getProviders?.provider || []).map((provider) => {
      return { key: provider.name, value: provider._id };
    });
    setProviders(providers);
  }, [data]);

  return (
    <>
      <Grid item container flexWrap="wrap" flexDirection={{xs: "column", sm:"row"}} spacing={2} alignItems={{lg: "flex-end"}}>
        {/* FILTER BY TYPE */}
        <Grid item>
          <Filter
            onHandleChange={(e) => filter(filterEnum.TYPE, e?.target?.value)}
            onClickClearBtn={() => filter(filterEnum.TYPE, "")}
            options={[{ key: "Type", value: "" }, ...consultationTypes]}
            name="consultation_type_filter"
            value={filterState.type}
            hasClearBtn={true}
            disable={disabled}
            variant="small"
          />
        </Grid>

        {/* FILTER BY STATUS */}
        <Grid item>
          <Filter
            onHandleChange={(e) => filter(filterEnum.STATUS, e?.target?.value)}
            onClickClearBtn={() => filter(filterEnum.STATUS, "")}
            options={[{ key: "Status", value: "" }, ...consultationStatus]}
            name="consultation_status_filter"
            value={filterState.status}
            hasClearBtn={true}
            disable={disabled}
            variant="small"
          />
        </Grid>

        {/* FILTER BY CONTACT  MEDIUM */}
        <Grid item>
          <Filter
            onHandleChange={(e) => filter(filterEnum.CONTACT, e?.target?.value)}
            onClickClearBtn={() => filter(filterEnum.CONTACT, "")}
            options={[
              { key: "Medium", value: "" },
              ...consultationContactModes
            ]}
            name="consultation_contact_filter"
            value={filterState.contact}
            hasClearBtn={true}
            disable={disabled}
            variant="small"
          />
        </Grid>

        {/* FILTER BY CONTACT  DATE */}
        <Grid item>
          <Filter
            onHandleChange={(e) => {
              const value = e?.target?.value;
              setDateFilter(value);
              if (value === "specify_period") return;

              const dates = calculateFilterDate(value);
              setFilterState((prev) => ({ ...prev, ...dates }));
              onFilterChange({ ...dates });
            }}
            onClickClearBtn={() => {
              setDateFilter("");
              filter("startDate", "");
              filter("endDate", "");
            }}
            options={[{ key: "Date", value: "" }, ...consultationDates]}
            name="consultation_date_filter"
            value={dateFilter}
            hasClearBtn={true}
            disable={disabled}
            variant="small"
          />
        </Grid>

        {/*  FILTER BY PROVIDER */}
        <Grid item>
          <Autocomplete
            id="providerId"
            name="providerId"
            size="small"
            loading={loading}
            className={classes.autoComplete}
            defaultValue={{
              key: "Provider",
              value: ""
            }}
            disabled={disabled}
            onInputChange={(x) => x}
            onChange={(e, info) => {
              const providerId = info?.value;
              filter(filterEnum.PROVIDERID, providerId);
            }}
            options={providers}
            getOptionLabel={(option) => option.key}
            renderOption={(props, option) => <li {...props}>{option?.key}</li>}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Provider" />
            )}
          />
        </Grid>
      </Grid>
      {dateFilter === "specify_period" && (
        <Grid container gap={2} sx={{ marginTop: "2rem" }}>
          <Grid item>
            <Typography>Start date</Typography>
            <input
              type="date"
              disabled={disabled}
              style={dateInputStyle}
              value={filterState?.startDate}
              onChange={(e) =>
                setFilterState((prev) => ({
                  ...prev,
                  startDate: e?.target?.value
                }))
              }
            />
          </Grid>
          <Grid item>
            <Typography>End date</Typography>
            <input
              type="date"
              style={dateInputStyle}
              disabled={disabled}
              value={filterState?.endDate}
              onChange={(e) =>
                setFilterState((prev) => ({
                  ...prev,
                  endDate: e?.target?.value
                }))
              }
            />
          </Grid>
          <Grid item alignSelf="flex-end">
            <CustomButton
              title="Select"
              width="100%"
              disabled={disabled}
              type={buttonType}
              onClick={() => {
                try {
                  const { endDate, startDate } = filterState;
                  const parseDate = (date) => new Date(Date.parse(date));
                  const isEndDateAfter = isAfter(
                    parseDate(endDate),
                    parseDate(startDate)
                  );
                  if (!isEndDateAfter)
                    throw Error("End date must be after Start date");
                  onFilterChange({});
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                  displayAlert("error", error?.message);
                }
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

ConsultationsFilters.propTypes = {
  filterState: PropTypes.object.isRequired,
  setFilterState: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default ConsultationsFilters;
