import React, { useEffect, useState } from "react";
import t from "prop-types";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { differenceInCalendarYears } from "date-fns";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import useAlert from "hooks/useAlert";
import { CustomButton } from "components/Utilities";
import { FormikControl } from "components/validation";
import { getPlans } from "components/graphQL/useQuery";
import { useLazyQuery, useMutation } from "@apollo/client";
import { addHMOEnrolleInitialValues } from "helpers/mockData";
import { dateMoment, removeEmptyStringValues } from "helpers/func";
import { addHMOEnrolleeValidationSchema } from "helpers/validationSchemas";
import { createEnrollee, updateEnrollee } from "components/graphQL/Mutation"

const AddEditHMOEnrolleeForm = ({ type, editInitialValues, onSuccess }) => {
  const theme = useTheme();
  const { hmoId } = useParams();
  const [date] = useState(Date.now());
  const [fetchPlans] = useLazyQuery(getPlans);
  const [expired, setExpired] = useState(false);
  const [noExpiry, setNoExpiry] = useState(false);
  const { displayAlert, getErrorMsg } = useAlert();
  const [planOptions, setPlanOptions] = useState([]);
  const [addHMOEnrollee] = useMutation(createEnrollee);
  const [updateHMOEnrollee] = useMutation(updateEnrollee);
  const formInitialValues =
    type === "edit" ? editInitialValues : addHMOEnrolleInitialValues;

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
  };
  const disable = type === "edit";

  if (type === "edit") {
    const diff = differenceInCalendarYears(
      new Date(Date.parse(`${formInitialValues?.expiryDate}`)),
      Date.now()
    );
    if (diff > 10) {
      setNoExpiry(true);
    }
  }

  useEffect(() => {
    fetchPlans({
      variables: { type: "hmo" },
    })
      .then(({ data }) => {
        const options = (data?.getPlans?.data || []).map((option) => {
          return { key: option?.name, value: option?._id };
        });
        setPlanOptions(options);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [fetchPlans]);

  const onAddSubmit = async (values) => {
    const formatedDate = dateMoment(values.expiryDate);
    const variables = removeEmptyStringValues(values);
    try {
      if (!variables.providerId) {
        throw Error("Provider ID not found!");
      }
      const { data } = await addHMOEnrollee({
        variables: { expiryDate: formatedDate, ...variables },
      });
      if (data) {
        displayAlert("success", "Enrollee added successfully");
        onSuccess();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };

  const onUpdateSubmit = async (values) => {
    const formatedDate = dateMoment(values.expiryDate);
    const variables = removeEmptyStringValues(values);
    try {
      if (!variables?.providerId && !variables?.id) {
        throw Error("Provider ID or Enrollee ID found!");
      }
      const { data } = await updateHMOEnrollee({
        variables: { expiryDate: formatedDate, ...variables },
      });
      if (data) {
        displayAlert("success", "Enrollee updated successfully");
        onSuccess();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={(values) =>
        type === "edit"
          ? onUpdateSubmit({
              id: editInitialValues._id,
              providerId: hmoId,
              ...values,
            })
          : onAddSubmit({ providerId: hmoId, ...values })
      }
      validationSchema={addHMOEnrolleeValidationSchema}
      validateOnChange={true}
      validateOnMount={false}
      validateOnBlur={true}
    >
      {({ isSubmitting, setFieldValue, setValues, initialValues }) => {
        return (
          <Form style={{ marginTop: "1rem" }}>
            <Grid container direction="column" gap={2}>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="First Name"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter first name"
                  disabled={disable}
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Last Name"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter last name"
                  disabled={disable}
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="HMO ID"
                  id="hmoID"
                  name="hmoId"
                  placeholder="Enter HMO ID"
                  disabled={disable}
                />
              </Grid>

              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Email"
                  id="email"
                  name="email"
                  placeholder="Enter email address"
                  disabled={disable}
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="Phone number"
                  id="phone_number"
                  name="phone"
                  placeholder="Enter phone number"
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="input"
                  label="HMO Plan"
                  id="plan"
                  name="plan"
                  placeholder="Enter HMO Plan"
                />
              </Grid>
              <Grid item container direction="column" gap={1}>
                {noExpiry === true ? (
                  ""
                ) : expired === true ? (
                  ""
                ) : (
                  <FormikControl
                    control="date"
                    label="Expiry date (for HMO plan)"
                    id="expiry"
                    name="expiryDate"
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    startDate={date}
                  />
                )}
                <Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="expire"
                        checked={noExpiry}
                        onChange={() => {
                          const newChecked = !noExpiry;
                          setNoExpiry(newChecked);
                          setExpired(false);
                          if (newChecked) {
                            const date = new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() + 100
                              )
                            );
                            setFieldValue("expiryDate", date);
                          } else {
                            setFieldValue(
                              "expiryDate",
                              initialValues?.expiryDate
                            );
                          }
                        }}
                      />
                    }
                    label="No expiry date"
                  />
                  {type === "edit" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="expire"
                          checked={expired}
                          onChange={() => {
                            const newChecked = !expired;
                            setExpired(newChecked);
                            setNoExpiry(false);
                            if (newChecked) {
                              const date = new Date(
                                new Date().setDate(new Date().getDate() - 1)
                              );
                              setFieldValue("expiryDate", date);
                            } else {
                              setFieldValue(
                                "expiryDate",
                                initialValues?.expiryDate
                              );
                            }
                          }}
                        />
                      }
                      label="Expired"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container direction="column" gap={1}>
                <FormikControl
                  control="select"
                  options={[{ key: "Plan", value: "" }, ...planOptions]}
                  placeholder="Select Plan"
                  name="planId"
                  label="Heala Access Plan"
                />
              </Grid>

              <Grid item container>
                <CustomButton
                  title={type === "edit" ? "Update Enrollee" : "Add Enrollee"}
                  width="100%"
                  type={buttonType}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

AddEditHMOEnrolleeForm.propTypes = {
  onSuccess: t.func.isRequired,
  editInitialValues: t.object,
  type: t.string.isRequired,
};

export default AddEditHMOEnrolleeForm;
