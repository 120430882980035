import React from "react";
import PropTypes from "prop-types";
import MainModal from "./MainModal";
import AddEditHMOForm from "components/Forms/AddEditHMOForm";



const AddEditHMOModal = ({
  isOpen,
  setIsOpen,
  type,
  initialValues,
  onSuccess,
}) => {
  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      headerText={type === "edit" ? "Edit HMO" : "Add New HMO"}
    >
      <AddEditHMOForm
        type={type}
        initialValues={initialValues}
        onSuccess={() => onSuccess()}
      />
    </MainModal>
  );
};

AddEditHMOModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["edit", "add"]),
  initialValues: PropTypes.object.isRequired,
};

export default AddEditHMOModal;
