const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles((theme) => ({
  parentGridWrapper: {
    background: "#fff",
    borderRadius: "1rem",
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "4rem",

    "&:not(:last-of-type)": {
      marginBottom: "5rem",
    },
  },

  infoBadge: {
    "&.MuiChip-root": {
      fontSize: "1.5rem",
      borderRadius: "1.5rem",
    },
  },

  title: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
      fontWeight: 600,
      fontSize: "0.9em",
      whitespace: "wrap",
    },
  },

  info: {
    "&.MuiTypography-root": {
      color: theme.palette.common.grey,
      fontWeight: 400,
      whitespace: "wrap",
    },
  },
  item: {
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "nowrap",
    // justifyContent: "space-between",
    "&.MuiGrid-root > *": {
      flex: 1,
    },
  },
  subItem: {
    "&.MuiGrid-container": {
      flexDirection: "column",
      wordBreak: "break-word",
    },
  },

  underline: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
