import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useAlert from "hooks/useAlert";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { CustomButton } from "components/Utilities";
import { useMutation, useQuery } from "@apollo/client";
import { getProviders } from "components/graphQL/useQuery";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { initializeDoctorsPayout } from "components/graphQL/Mutation";

const useStyles = makeStyles((theme) => ({
  FormLabel: {
    "&.MuiFormLabel-root": {
      ...theme.typography.FormLabel
    }
  },
  select: {
    "&.MuiOutlinedInput-root": {
      fontWeight: "200 !important",
      color: `${theme.palette.common.grey} !important`,
      backgroundColor: "#FAFAFA",
      height: ({ height }) => `${height}`,
      fontSize: "14px !important",
      borderRadius: "8px",
      border: "1px solid #E0E0E0 !important",

      "&:disabled": {
        backgroundColor: "#E0E0E0 !important"
      },

      "&>fieldset": {
        border: 0
      }
    },

    "&.MuiTypography-root": {
      fontWeight: "200 !important",
      color: `${theme.palette.common.grey} !important`,
      minHeight: 50,
      fontSize: "14px !important",
      borderRadius: "12px"
    }
  }
}));

const PayoutForm = ({ onDoctorsPayoutSuccess }) => {
  const theme = useTheme();
  const [providers, setProviders] = useState([]);
  const { displayAlert, getErrorMsg } = useAlert();
  const [providerId, setProviderId] = useState("");
  const { data, loading } = useQuery(getProviders, { variables: { page: -1 } });
  const [payoutDoctors, { loading: doctorsPayoutLoading }] = useMutation(
    initializeDoctorsPayout
  );

  const styleProps = {
    height: "48px"
  };
  const classes = useStyles(styleProps);

  const payoutBtn = {
    background: theme.palette.common.blue,
    hover: "#2b4a94",
    active: theme.palette.primary.dark,
    disabled: "#868686"
  };

  useEffect(() => {
    const providers = (data?.getProviders?.provider || []).map((provider) => {
      return { key: provider.name, value: provider._id };
    });
    setProviders(providers);
  }, [data]);

  return (
    <Grid container justifyContent="center">
      <Box
        sx={{
          bgcolor: "background.paper",
          width: 600,
          margin: "2rem 0rem",
          borderRadius: "5px"
        }}
      >
        {/* ======= DOCTORS PAYOUT FORM ========*/}
        <Grid sx={{ width: 400 }} margin="auto">
          <Grid container gap={2} alignItems="center">
            <Grid item flex="1 1 0%">
              <Autocomplete
                id="providerId"
                name="providerId"
                loading={loading}
                className={classes.select}
                onInputChange={() => null}
                onChange={(e, info) => {
                  const providerId = info?.value;
                  setProviderId(providerId);
                }}
                options={providers}
                getOptionLabel={(option) => option.key}
                renderOption={(props, option) => (
                  <li {...props}>{option?.key}</li>
                )}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select a provider" />
                )}
              />
            </Grid>
            <Grid item>
              <CustomButton
                title="Process payout"
                isSubmitting={doctorsPayoutLoading}
                onClick={() => {
                  if (!providerId && providerId === "") return;
                  payoutDoctors({ variables: { providerId } })
                    .then(() => {
                      displayAlert("success", "Doctors payout initialized!");
                      onDoctorsPayoutSuccess();
                    })
                    .catch((error) => {
                      // eslint-disable-next-line no-console
                      console.error(error);
                      const errMsg = getErrorMsg(error);
                      displayAlert("error", errMsg);
                    });
                }}
                type={payoutBtn}
                width="auto"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* </TabPanel>
        </SwipeableViews> */}
      </Box>
    </Grid>
  );
};

PayoutForm.propTypes = {
  onDoctorsPayoutSuccess: PropTypes.func.isRequired
};

export default PayoutForm;
