import { SvgIcon } from "@mui/material";
import React from "react";

function LabelIcon(props) {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <path
        d="M3.82236 14.0255L7.97486 18.178C9.67986 19.883 12.4482 19.883 14.1624 18.178L18.1865 14.1538C19.8915 12.4488 19.8915 9.68047 18.1865 7.96631L14.0249 3.82297C13.154 2.95214 11.9532 2.48464 10.7249 2.54881L6.14153 2.76881C4.30819 2.85131 2.85069 4.30881 2.75903 6.13297L2.53903 10.7163C2.48403 11.9538 2.95153 13.1546 3.82236 14.0255Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.70817 11.0013C9.97382 11.0013 10.9998 9.97529 10.9998 8.70964C10.9998 7.44398 9.97382 6.41797 8.70817 6.41797C7.44252 6.41797 6.4165 7.44398 6.4165 8.70964C6.4165 9.97529 7.44252 11.0013 8.70817 11.0013Z"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default LabelIcon;
