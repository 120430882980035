import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Field, ErrorMessage } from "formik";
import { FormLabel, Grid, Input as MUIInput } from "@mui/material";
import { TextError } from "components/Utilities/TextError";

const useStyles = makeStyles((theme) => ({
  input: {
    ...theme.typography.input,
    "& .MuiInputBase-root:hover": {
      border: "1px solid red"
    },
    "&.MuiInputBase-root:before": {
      border: "none"
    },
    "&.MuiInputBase-root:after": {
      border: "none"
    },
    "&.MuiInputBase-root:hover:before": {
      border: "none"
    },
    "&.MuiInputBase-root:focus:before": {
      border: "none"
    },
    "&.MuiInputBase-root.Mui-disabled": {
      backgroundColor: "#f1f1f1 !important"
    }
  },
  FormLabel: {
    "&.MuiFormLabel-root": {
      ...theme.typography.FormLabel
    }
  }
}));

const Input = (props) => {
  const { label, name, ...rest } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" gap={1}>
      <FormLabel className={classes.FormLabel}>{label}</FormLabel>
      <Field
        id={name}
        name={name}
        className={classes.input}
        as={MUIInput}
        {...rest}
        style={{ minHeight: 50 }}
      />
      <ErrorMessage name={name} component={TextError} />
    </Grid>
  );
};
Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string
};

export default Input;
