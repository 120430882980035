import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import { TextError } from "components/Utilities/TextError";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";

const useStyles = makeStyles((theme) => ({
  FormLabel: {
    "&.MuiFormLabel-root": {
      ...theme.typography.FormLabel
    }
  },

  dateInput: {
    "&>.MuiOutlinedInput-root": {
      height: "50px",
      border: "none"
    }
  }
}));

export const Dates = ({
  name,
  value,
  setFieldValue,
  onBlur,
  startDate,
  endDate
}) => {
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        className={classes.dateInput}
        name={name}
        onChange={(value) => setFieldValue(name, value)}
        value={value}
        onBlur={onBlur}
        minDate={startDate}
        maxDate={endDate}
        // eslint-disable-next-line no-console
        onError={(err) => console.error(err)}
        slotProps={{
          textField: {
            sx: {
              padding: "-12px",
              border: "2px solid #E0E0E0",
              borderRadius: "12px"
            }
          }
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     sx={{
        //       padding: "-12px",
        //       border: "2px solid #E0E0E0",
        //       borderRadius: "12px"
        //     }}
        //   />
        // )}
      />
    </LocalizationProvider>
  );
};

Dates.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  children: PropTypes.node,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onBlur: PropTypes.func
};

const DateComponent = (props) => {
  const { name, label, ...rest } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" gap={1}>
      <FormLabel className={classes.FormLabel}>{label}</FormLabel>
      <Field name={name} as={Dates} label={label} {...rest} />
      <ErrorMessage name={name} component={TextError} />
    </Grid>
  );
};

DateComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string
};

export default DateComponent;

export const DatePickerComponent = ({ onHandleChange, ...props }) => {
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        className={classes.dateInput}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              padding: "-12px",
              border: "2px solid #E0E0E0",
              borderRadius: "12px"
            }}
          />
        )}
        onChange={(value) => onHandleChange(value)}
        {...props}
      />
    </LocalizationProvider>
  );
};

DatePickerComponent.propTypes = {
  onHandleChange: PropTypes.func
};
