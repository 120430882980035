import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { CaseNoteContainer } from "./CaseNoteContainer";
import StatusPill from "components/Utilities/StatusPill";
import { useStyles } from "styles/singleConsultationPage";
import { Avatar, Grid, Rating, Typography } from "@mui/material";

export const PatientInfoCard = ({ consultationInfo }) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    patient,
    discomfortLevel,
    description,
    firstNotice,
    symptoms,
    patientJoined,
    patientSatisfied,
    patientEndCommunicationReason,
    rating,
  } = consultationInfo;
  const hasPatient = patient?.firstName && patient?.lastName;

  return (
    <Grid
      item
      container
      direction="column"
      className={classes.parentGridWrapper}
    >
      <Typography
        variant="h3"
        sx={{
          textDecoration: "underline",
          marginBottom: "2rem",
        }}
      >
        Patient information
      </Typography>

      <CaseNoteContainer
        item
        container
        flexWrap="wrap"
        display={{ lg: "grid", md: "grid", sm: "block" }}
        gridTemplateColumns={{ lg: "1fr 1fr 1fr" }}
        className={classes.item}
        alignItems={{ md: "center", xs: "flex-start", sm: "flex-start" }}
        rowGap={{ xs: "1.5rem", sm: "1.5rem", lg: "3rem" }}
        columnGap={{ xs: "1.5rem", sm: "1.5rem" }}
      >
        {/* PATIENT NAME */}
        <Grid item>
          <Grid
            item
            container
            flexDirection="column"
            gap={1}
            className={classes.subItem}
          >
            <Grid item>
              <Typography variant="body3" className={classes.title}>
                Patient name:
              </Typography>
            </Grid>
            <Grid item className={classes.info}>
              {patient && hasPatient ? (
                <Grid
                  item
                  container
                  alignItems="center"
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/patients/${patient?._id}`);
                  }}
                >
                  <Grid item marginRight={2}>
                    <Avatar
                      src={patient?.image}
                      alt={`Display photo of the ${patient?.firstName}`}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.info} ${classes.underline}`}
                    >{`${patient?.firstName} ${patient.lastName}`}</Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography className={classes.info}>No Patient</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/*  PATIENT JOINED */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            <Grid item>
              <Typography className={classes.title}>Patient joined:</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                {patientJoined ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* PATIENT CONSULTATION SUCCESSFUL */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            <Grid item>
              <Typography className={classes.title}>
                Consultation successful (Patient):
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                {patientSatisfied ? "Yes" : "No"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* PATIENT END CALL REASON */}
        <Grid item>
          <Grid
            item
            container
            flexDirection="column"
            gap={1}
            className={classes.subItem}
          >
            <Grid item>
              <Typography variant="body3" className={classes.title}>
                Patient end call reason:
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                {patientEndCommunicationReason
                  ? patientEndCommunicationReason
                  : "No reason"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  SYMPTOMS */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            <Grid item>
              <Typography className={classes.title}>Symptoms:</Typography>
            </Grid>
            <Grid item container wrap="wrap" gap={1}>
              {symptoms ? (
                symptoms.map((symptom, index) => {
                  return (
                    <StatusPill
                      key={index}
                      label={symptom.name}
                      type="normal"
                    />
                  );
                })
              ) : (
                <Typography variant="body1">No symptoms</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/*  DISCOMFORT LEVEL */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            <Grid item>
              <Typography className={classes.title}>
                Discomfort level:
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                {discomfortLevel ? discomfortLevel : "No level."}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*  FIRST TIME NOTICE */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            <Grid item>
              <Typography className={classes.title}>
                First time notice:
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>{firstNotice}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* DESCRIPTION */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            <Grid item>
              <Typography className={classes.title}>Description:</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.info}>
                {(description && description) || "No comment."}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* RATING */}
        <Grid item>
          <Grid container className={classes.subItem} gap={1}>
            <Grid item>
              <Typography className={classes.title}>
                Consultation rating:
              </Typography>
            </Grid>
            <Grid item>
              <Rating name="read-only" value={rating?.score || 0} readOnly />
              <Typography className={classes.info}>
                {(rating && rating?.review) || "No comment."}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CaseNoteContainer>
    </Grid>
  );
};

PatientInfoCard.propTypes = {
  consultationInfo: PropTypes.object.isRequired,
};
