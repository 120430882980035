import { SvgIcon } from "@mui/material";
import React from "react";

function Medical(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.6572 1.75715C12.7825 0.631974 14.3087 -9.37578e-05 15.9 1.04315e-08C17.4914 9.37786e-05 19.0175 0.632341 20.1427 1.75765C21.2678 2.88297 21.8999 4.40917 21.8998 6.00051C21.8997 7.59185 21.2675 9.11797 20.1422 10.2432L10.2422 20.1432C9.11685 21.2683 7.59065 21.9004 5.99931 21.9003C4.40797 21.9002 2.88185 21.268 1.75667 20.1427C0.631485 19.0173 -0.000582039 17.4911 -0.000488271 15.8998C-0.000394503 14.3085 0.631853 12.7823 1.75717 11.6572L11.6572 1.75715ZM18.7272 8.82815L14.4852 13.0712L8.82817 7.41415L13.0712 3.17215C13.441 2.79386 13.8822 2.4927 14.3693 2.2861C14.8563 2.0795 15.3795 1.97157 15.9086 1.96856C16.4376 1.96556 16.962 2.06753 17.4514 2.26858C17.9407 2.46963 18.3854 2.76576 18.7595 3.13982C19.1336 3.51389 19.4298 3.95845 19.631 4.44778C19.8321 4.93711 19.9342 5.46148 19.9313 5.99053C19.9283 6.51957 19.8205 7.04279 19.614 7.52987C19.4075 8.01696 19.1064 8.45823 18.7282 8.82815H18.7272Z" />
    </SvgIcon>
  );
}

export default Medical;
