// Dashboard Waiting List Table Dummy Data
export const waitingListRows = [
  {
    id: 10,
    name: "John Doe",
    time: "3.45s ago",
  },
  {
    id: 11,
    name: "Jane Doe",
    time: "3.45s ago",
  },
  {
    id: 21,
    name: "Sara Smith",
    time: "3.45s ago",
  },
  {
    id: 31,
    name: "Raphael Igbenedion",
    time: "3.45s ago",
  },
];

// Dashboard Availability Table Dummy Data
export const availabilityRows = [
  {
    id: 0,
    name: "John Doe",
    specialization: "Dentistry",
    time: "7:50am - 2:00pm",
  },
  {
    id: 1,
    name: "Alan Smith",
    specialization: "Pediatry",
    time: "7:50am - 2:00pm",
  },
  {
    id: 2,
    name: "Sophie Taylor",
    specialization: "Optometry",
    time: "7:50am - 2:00pm",
  },
  {
    id: 3,
    name: "Jane Doe",
    specialization: "Dermatology",
    time: "7:50am - 2:00pm",
  },
  {
    id: 4,
    name: "Jessica Brown",
    specialization: "Dentistry",
    time: "7:50am - 2:00pm",
  },
];

// Patients Table Dummy Data
export const patientsRows = [
  {
    id: 0,
    patientName: "Hameed Jimoh",
    plan: "Plan 1",
    consultations: "20",
    status: "active",
  },
  {
    id: 1,
    patientName: "Kazeem Raheem",
    plan: "Plan 2",
    consultations: "24",
    status: "active",
  },
  {
    id: 2,
    patientName: "Rahmeem Ahmed",
    plan: "Plan 3",
    consultations: "12",
    status: "blocked",
  },
  {
    id: 3,
    patientName: "Hameed Jimoh",
    plan: "Plan 1",
    consultations: "20",
    status: "active",
  },
  {
    id: 4,
    patientName: "Hameed Jimoh",
    plan: "Plan 1",
    consultations: "20",
    status: "active",
  },
  {
    id: 5,
    patientName: "Hameed Jimoh",
    plan: "Plan 1",
    consultations: "20",
    status: "active",
  },
  {
    id: 6,
    patientName: "Hameed Jimoh",
    plan: "Plan 1",
    consultations: "20",
    status: "active",
  },
  {
    id: 7,
    patientName: "Hameed Jimoh",
    plan: "Plan 1",
    consultations: "20",
    status: "active",
  },
];

// Patients > Consultation Table Dummy Data
export const consultationsRows = [
  {
    id: 100,
    name: "Raphael Igbenedion",
    date: "7 July 2021",
    description: "Health tips and counseling",
  },
  {
    id: 101,
    name: "Raphael Igbenedion",
    date: "7 July 2021",
    description: "Health tips and counseling",
  },
  {
    id: 102,
    name: "Raphael Igbenedion",
    date: "7 July 2021",
    description: "Health tips and counseling",
  },
  {
    id: 103,
    name: "Raphael Igbenedion",
    date: "7 July 2021",
    description: "Health tips and counseling",
  },
  {
    id: 104,
    name: "Raphael Igbenedion",
    date: "7 July 2021",
    description: "Health tips and counseling",
  },
  {
    id: 105,
    name: "Raphael Igbenedion",
    date: "7 July 2021",
    description: "Health tips and counseling",
  },
  {
    id: 106,
    name: "Raphael Igbenedion",
    date: "7 July 2021",
    description: "Health tips and counseling",
  },
  {
    id: 107,
    name: "Raphael Igbenedion",
    date: "7 July 2021",
    description: "Health tips and counseling",
  },
];

// Patients > Prescriptions Table Dummy Data
export const prescriptionsRows = [
  {
    id: 200,
    date: "07 July 2021",
    details: "Details regarding the prescription",
    dosage: 8,
  },
  {
    id: 201,
    date: "07 July 2021",
    details: "Details regarding the prescription",
    dosage: 5,
  },
  {
    id: 202,
    date: "07 July 2021",
    details: "Details regarding the prescription",
    dosage: 7,
  },
  {
    id: 203,
    date: "07 July 2021",
    details: "Details regarding the prescription",
    dosage: 4,
  },
  {
    id: 204,
    date: "07 July 2021",
    details: "Details regarding the prescription",
    dosage: 5,
  },
  {
    id: 205,
    date: "07 July 2021",
    details: "Details regarding the prescription",
    dosage: 6,
  },
];

// Patients > Medication Table Dummy Data
export const medicationsRows = [
  {
    id: 300,
    date: "7 July 2021",
    name: "Name of Medication",
    prescription: "Type of Prescription",
    caregiver: "Raphael Igbenedion",
  },
  {
    id: 301,
    date: "7 July 2021",
    name: "Name of Medication",
    prescription: "Type of Prescription",
    caregiver: "Raphael Igbenedion",
  },
  {
    id: 302,
    date: "7 July 2021",
    name: "Name of Medication",
    prescription: "Type of Prescription",
    caregiver: "Raphael Igbenedion",
  },
  {
    id: 303,
    date: "7 July 2021",
    name: "Name of Medication",
    prescription: "Type of Prescription",
    caregiver: "Raphael Igbenedion",
  },
  {
    id: 304,
    date: "7 July 2021",
    name: "Name of Medication",
    prescription: "Type of Prescription",
    caregiver: "Raphael Igbenedion",
  },
  {
    id: 305,
    date: "7 July 2021",
    name: "Name of Medication",
    prescription: "Type of Prescription",
    caregiver: "Raphael Igbenedion",
  },
  {
    id: 306,
    date: "7 July 2021",
    name: "Name of Medication",
    prescription: "Type of Prescription",
    caregiver: "Raphael Igbenedion",
  },
  {
    id: 307,
    date: "7 July 2021",
    name: "Name of Medication",
    prescription: "Type of Prescription",
    caregiver: "Raphael Igbenedion",
  },
];

// HCPS Table Dummy Data
export const hcpsRows = [
  {
    id: 400,
    name: "Raphael Igbinedion",
    specialization: "Dentistry",
    consultation: 22,
    hospital: "Federal Teaching Hospital, Abakaliki",
    status: "Active",
  },
  {
    id: 401,
    name: "Raphael Igbinedion",
    specialization: "Dentistry",
    consultation: 20,
    hospital: "Federal Teaching Hospital, Akure",
    status: "Blocked",
  },
  {
    id: 402,
    name: "Raphael Igbinedion",
    specialization: "Pediatry",
    consultation: 40,
    hospital: "Federal Teaching Hospital, Owerri",
    status: "Active",
  },
  {
    id: 403,
    name: "Raphael Igbinedion",
    specialization: "Optometry",
    consultation: 15,
    hospital: "Federal Teaching Hospital, Akure",
    status: "Active",
  },
  {
    id: 404,
    name: "Raphael Igbinedion",
    specialization: "Dentistry",
    consultation: 23,
    hospital: "Federal Teaching Hospital, Abakaliki",
    status: "Blocked",
  },
  {
    id: 405,
    name: "Raphael Igbinedion",
    specialization: "Dentistry",
    consultation: 32,
    hospital: "Federal Teaching Hospital, Akure",
    status: "Active",
  },
];

// HCPS Table Dummy Data
export const partnersRows = [
  {
    id: 500,
    name: "General Hospital, Lekki",
    category: "Hospital",
  },
  {
    id: 501,
    name: "H-Medix",
    category: "Pharmacy",
  },
  {
    id: 502,
    name: "X Lab",
    category: "Diagnostic Center",
  },
];

// HCPS Table Dummy Data
export const messagesRows = [
  {
    id: 600,
    name: "Allison Igbinedion",
    subject: "Appreciation message to Heala  team",
    date: "July 7, 2021",
    time: "10:00 AM",
  },
  {
    id: 601,
    name: "Allison Igbinedion",
    subject: "Appreciation message to Heala  team",
    date: "July 7, 2021",
    time: "10:00 AM",
  },
  {
    id: 602,
    name: "Allison Igbinedion",
    subject: "Appreciation message to Heala  team",
    date: "July 7, 2021",
    time: "10:00 AM",
  },
  {
    id: 603,
    name: "Allison Igbinedion",
    subject: "Appreciation message to Heala  team",
    date: "July 7, 2021",
    time: "10:00 AM",
  },
  {
    id: 604,
    name: "Allison Igbinedion",
    subject: "Appreciation message to Heala  team",
    date: "July 7, 2021",
    time: "10:00 AM",
  },
  {
    id: 605,
    name: "Allison Igbinedion",
    subject: "Appreciation message to Heala  team",
    date: "July 7, 2021",
    time: "10:00 AM",
  },
];

// HCPS Patients Table Dummy Data
export const hcpPatientsRows = [
  { id: 700, name: "Raphael Igbinedion" },
  { id: 701, name: "Raphael Igbinedion" },
  { id: 702, name: "Raphael Igbinedion" },
  { id: 703, name: "Raphael Igbinedion" },
  { id: 704, name: "Raphael Igbinedion" },
  { id: 705, name: "Raphael Igbinedion" },
  { id: 706, name: "Raphael Igbinedion" },
  { id: 707, name: "Raphael Igbinedion" },
];
