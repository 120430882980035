import { SvgIcon } from "@mui/material";
import React from "react";

function VerifiedIcon(props) {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <path
        d="M7.68164 10.9996L9.89081 13.2179L14.3183 8.78125"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.85414 2.24547C10.4866 1.70464 11.5225 1.70464 12.1641 2.24547L13.6125 3.49214C13.8875 3.73047 14.4008 3.92297 14.7675 3.92297H16.3258C17.2975 3.92297 18.095 4.72047 18.095 5.69214V7.25047C18.095 7.60797 18.2875 8.13047 18.5258 8.40547L19.7725 9.8538C20.3133 10.4863 20.3133 11.5221 19.7725 12.1638L18.5258 13.6121C18.2875 13.8871 18.095 14.4005 18.095 14.7671V16.3255C18.095 17.2971 17.2975 18.0946 16.3258 18.0946H14.7675C14.41 18.0946 13.8875 18.2871 13.6125 18.5255L12.1641 19.7721C11.5316 20.313 10.4958 20.313 9.85414 19.7721L8.40581 18.5255C8.13081 18.2871 7.61747 18.0946 7.25081 18.0946H5.66497C4.69331 18.0946 3.89581 17.2971 3.89581 16.3255V14.758C3.89581 14.4005 3.70331 13.8871 3.47414 13.6121L2.23664 12.1546C1.70497 11.5221 1.70497 10.4955 2.23664 9.86297L3.47414 8.40547C3.70331 8.13047 3.89581 7.61714 3.89581 7.25964V5.68297C3.89581 4.7113 4.69331 3.9138 5.66497 3.9138H7.25081C7.60831 3.9138 8.13081 3.7213 8.40581 3.48297L9.85414 2.24547Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default VerifiedIcon;
