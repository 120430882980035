import React from "react";
import PropTypes from "prop-types";
import MainModal from "./MainModal";
import { UploadListForm } from "components/Forms/UploadListForm";

const UploadListModal = ({
  isOpen,
  setIsOpen,
  title,
  handleSubmit,
  formValSchema,
}) => {
  return (
    <MainModal isOpen={isOpen} headerText={title} setIsOpen={setIsOpen}>
      <UploadListForm
        onSubmit={(values) => handleSubmit(values)}
        valSchema={formValSchema}
      />
    </MainModal>
  );
};

UploadListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValSchema: PropTypes.object,
};

export default UploadListModal;
