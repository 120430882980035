import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Grid } from "@mui/material";
import useAlert from "hooks/useAlert";
import { useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import MainModal from "components/modals/MainModal";
import { CustomButton } from "components/Utilities";
import { addPartner } from "components/graphQL/Mutation";
import { getPartners } from "components/graphQL/useQuery";
import FormikControl from "components/validation/FormikControl";
import { addNewPartnerValidationSchema } from "helpers/validationSchemas";

const categoryOptions = [
  { key: "Diagnostics", value: "diagnostics" },
  { key: "Pharmacy", value: "pharmacy" },
  { key: "Hospital", value: "hospital" },
  { key: "HMO", value: "hmo" },
];
const classificationOptions = [
  { key: "Primary Healthcare", value: "Primary Healthcare" },
  { key: "Secondary Healthcare", value: "Secondary Healthcare" },
];

const specializationOptions = [
  { key: "Dental Care", value: "Dental Care" },
  { key: "Eye Clinic", value: "Eye Clinic" },
  { key: "Skin Care", value: "Skin Care" },
  { key: "Mental Care", value: "Mental Care" },
];

const initialValues = {
  name: "",
  email: "",
  specialization: "Mental Care",
  category: "hospital",
  address: "",
  phone: "",
  classification: "Primary Healthcare",
  image: null,
};

const AddPartner = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const { displayAlert } = useAlert();
  const [addPartners] = useMutation(addPartner);

  const buttonType = {
    background: theme.palette.common.black,
    hover: theme.palette.primary.main,
    active: theme.palette.primary.dark,
    disabled: theme.palette.common.black,
  };

  const onSubmit1 = async (values, onSubmitProps) => {
    let { name, email, phone, category, specialization, image } = values;
    name = name.trim();

    try {
      await addPartners({
        variables: {
          name,
          email,
          category,
          specialization,
          phone,
          logoImageUrl: image,
          providerId: id,
        },
        refetchQueries: [
          {
            query: getPartners,
            variables: {
              provider: id,
            },
          },
        ],
      });
      displayAlert("success", "Partner added successfully");
      onSubmitProps.resetForm();
      handleClose(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      displayAlert("error", err);
    }
  };


  return (
    <MainModal
      isOpen={open}
      title="Add Admin"
      setIsOpen={handleClose}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit1}
        validationSchema={addNewPartnerValidationSchema}
        validateOnChange={false}
        validateOnMount={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, isValid, dirty, values }) => {
          const { classification, category: cat } = values;
          return (
            <Form style={{ marginTop: "1rem" }}>
              <Grid container direction="column" gap={1.5}>
                <Grid item container>
                  <Grid item container flexWrap="nowrap" gap={2}>
                    <Grid item xs={6}>
                      <FormikControl
                        control="input"
                        label="Name"
                        id="name"
                        name="name"
                        placeholder="Enter Partner name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikControl
                        control="input"
                        label="Email"
                        id="name"
                        name="email"
                        placeholder="Enter Email"
                      />
                    </Grid>
                  </Grid>

                  <Grid item container flexWrap="nowrap" gap={2}>
                    <Grid item xs={6}>
                      <FormikControl
                        control="input"
                        label="Phone Number"
                        id="phone"
                        name="phone"
                        placeholder="Enter Phone Number"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormikControl
                        control="select"
                        options={categoryOptions}
                        name="category"
                        label="Category"
                        placeholder="Category"
                      />
                    </Grid>
                  </Grid>

                  <Grid item container flexWrap="nowrap" gap={2}>
                    {classification === "Secondary Healthcare" && (
                      <Grid item xs={6}>
                        <FormikControl
                          control="select"
                          options={specializationOptions}
                          name="specialization"
                          label="Specialization"
                          id="specialization"
                          // placeholder="Select Specialization"
                        />
                      </Grid>
                    )}
                    {cat === "hospital" && (
                      <Grid item xs={6}>
                        <FormikControl
                          control="select"
                          options={classificationOptions}
                          name="classification"
                          label="Classification"
                          id="classification"
                          placeholder="select classification"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container flexWrap="nowrap" gap={2}>
                    <Grid item xs={6}>
                      <FormikControl
                        control="file"
                        name="image"
                        label="Company Logo"
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid item container>
                    <FormikControl
                      control="textarea"
                      name="address"
                      minRows={3}
                      label="Address"
                      placeholder="Enter address"
                    />
                  </Grid> */}
                </Grid>
                <Grid item container sx={{ mt: 1 }}>
                  <CustomButton
                    title="Add Admin"
                    width="100%"
                    type={buttonType}
                    isSubmitting={isSubmitting}
                    disabled={!(dirty || isValid)}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </MainModal>
  );
};

AddPartner.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  category: PropTypes.any,
  CategoryValue: PropTypes.any,
  id: PropTypes.string,
};

export default AddPartner;
