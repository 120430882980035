import CryptoJS from "crypto-js";

export const useTextCipher = () => {
  const keyString = process.env.REACT_APP_ENCRYPTION_KEY;

  function decrypt(encryptedWithIv) {
    try {
      if (!keyString || typeof keyString !== "string")
        throw Error("No Key found!");

      const key = CryptoJS.enc.Utf8.parse(keyString);

      // Extract the IV (24 characters because 16 bytes IV in Base64 is 24 characters)
      const ivBase64 = encryptedWithIv?.substring(0, 24);
      const encryptedStr = encryptedWithIv?.substring(24);
      const iv = CryptoJS.enc.Base64.parse(ivBase64);
      const decrypted = CryptoJS.AES.decrypt(encryptedStr, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Couldn't decrypt message", error);
      return encryptedWithIv;
    }
  }

  function encrypt(plainText) {
    try {
      if (!keyString || typeof keyString !== "string")
        throw Error("No Key found!");
      const key = CryptoJS.enc.Utf8.parse(keyString);
      const iv = CryptoJS.lib.WordArray.random(16);
      const encrypted = CryptoJS.AES.encrypt(plainText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      });

      return iv.toString(CryptoJS.enc.Base64) + encrypted.toString();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Couldn't decrypt message", error);
      return plainText;
    }
  }

  return {
    encrypt,
    decrypt,
  };
};
