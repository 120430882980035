import { useSelector, useDispatch } from "react-redux";
import { add, replace } from "../../store/slices/tableSlice";

export const useTableActions = () => {
  const dispatch = useDispatch();
  const { selectedRows } = useSelector((state) => state.table);

  const getRow = (rowId, rows) => {
    const row = (rows || []).find((row) => row?._id === rowId);
    return row;
  };

  const selectSingleRow = (checked, rowId, rows) => {
    const newSelectedRows = {};
    const row = getRow(rowId, rows);
    if (checked) {
      // ADDING ROW TO SELECTED ROWS
      const _id = row?._id;
      newSelectedRows[_id] = row;
      dispatch(add(newSelectedRows));
    } else {
      // REMOVING ROW FROM SELECTED ROWS
      const selectedRowsKeys = Object.keys(selectedRows) || [];
      for (let i = 0; i < selectedRowsKeys.length; i++) {
        if (!(selectedRowsKeys[i] === rowId)) {
          newSelectedRows[selectedRowsKeys[i]] =
            selectedRows[selectedRowsKeys[i]];
        }
      }
      dispatch(replace(newSelectedRows));
    }
  };

  const selectAll = (checked, rows) => {
    const newSelectedRows = {};

    if (checked) {
      for (let i = 0; i < (rows || []).length; i++) {
        const _id = rows[i]?._id;
        newSelectedRows[_id] = rows[i];
      }
      dispatch(add(newSelectedRows));
    } else {
      const tableRowsKeys = (rows || []).map((row) => row._id);
      const selectedRowsKeys = Object.keys(selectedRows) || [];
      for (let i = 0; i < selectedRowsKeys.length; i++) {
        if (!tableRowsKeys?.includes(selectedRowsKeys[i])) {
          newSelectedRows[selectedRowsKeys[i]] =
            selectedRows[selectedRowsKeys[i]];
        }
      }
      dispatch(replace(newSelectedRows));
    }
  };

  return { selectSingleRow, selectAll };
};
