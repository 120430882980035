import React, { useCallback, useState } from "react";
import t from "prop-types";
import { Grid } from "@mui/material";
import useAlert from "hooks/useAlert";
import { Search } from "components/Utilities";
import { deleteVar } from "helpers/filterHelperFunctions";

const CompoundSearch = ({
  queryParams,
  searchState,
  setPageInfo,
  setProfiles,
  getSearchPlaceholder,
}) => {
  const state = searchState || {
    value: "",
    filterBy: "search",
  };
  const { displayAlert, getErrorMsg } = useAlert();
  const { fetchData, variables, newVariables } = queryParams;
  const [searchValue, setSearchValue] = useState(state);

  const search = async (searchBy, searchVal) => {
    try {
      deleteVar(variables);
      let value = searchVal;

      const searchVariables =
        value === ""
          ? {
              ...newVariables,
            }
          : {
              [searchBy]: value,
              ...newVariables,
            };
      fetchData({
        variables: searchVariables,
      }).then(({ data }) => {
        if (!data) {
          deleteVar(variables);
          displayAlert("error", `Couldn't search for ${searchVal}`);
          throw Error(`Couldn't search for ${searchVal}`);
        }
        setProfiles(data); // set outside
        setPageInfo(data); // set outside
        deleteVar(variables);
      });
    } catch (error) {
      deleteVar(variables);
      // eslint-disable-next-line no-console
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };

  return (
    <Grid container spacing={2} flexWrap="wrap">
      <Grid item flex={1} width="100%">
        <Search
          height="48px"
          hasStartIcon={false}
          onChange={(e) => {
            let value = e.target.value;
            if (value === "") {
              search(searchValue, "");
            } else {
              setSearchValue({ ...searchValue, value });
            }
          }}
          placeholder={getSearchPlaceholder(searchValue.filterBy)}
          onClickSearchBtn={() => {
            search(searchValue.filterBy, searchValue.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

CompoundSearch.propTypes = {
  queryParams: t.object.isRequired,
  searchState: t.object,
  setPageInfo: t.func.isRequired,
  setProfiles: t.func.isRequired,
  getSearchPlaceholder: t.func,
  filterOptions: t.array,
};

export default CompoundSearch;

export const CustomSearch = ({
  queryParams,
  setData,
  searchBy,
  searchValue,
  setSearchValue,
}) => {
  const { displayAlert, getErrorMsg } = useAlert();
  const { fetchData, variables, newVariables } = queryParams;

  const search = useCallback(
    async (searchBy, searchVal) => {
      try {
        deleteVar(variables);
        let value = searchVal;

        const searchVariables =
          value === ""
            ? {
                ...newVariables,
              }
            : {
                [searchBy]: value,
                ...newVariables,
              };
        fetchData({
          variables: searchVariables,
        }).then(({ data }) => {
          if (!data) {
            deleteVar(variables);
            displayAlert("error", `Couldn't search for ${searchVal}`);
            throw Error(`Couldn't search for ${searchVal}`);
          }
          setData(data);
          deleteVar(variables);
        });
      } catch (error) {
        deleteVar(variables);
        // eslint-disable-next-line no-console
        console.error(error);
        const errMsg = getErrorMsg(error);
        displayAlert("error", errMsg);
      }
    },
    [displayAlert, fetchData, getErrorMsg, newVariables, setData, variables]
  );

  return (
    <Grid container spacing={2} flexWrap="wrap">
      <Grid item flex={1} width="100%">
        <Search
          height="48px"
          hasStartIcon={false}
          onChange={(e) => {
            let value = e.target.value;
            if (value === "") {
              search(searchBy, "");
            } else {
              setSearchValue(value);
            }
          }}
          placeholder="Search..."
          onClickSearchBtn={() => {
            search("name", searchValue);
          }}
        />
      </Grid>
    </Grid>
  );
};

CustomSearch.propTypes = {
  queryParams: t.object.isRequired,
  setData: t.func,
  searchBy: t.string,
  searchValue: t.string,
  setSearchValue: t.func,
};
