/* eslint-disable react/react-in-jsx-scope */
// Patients Route TableHeader
export const patientsHeadCells = [
  {
    id: 0,
    label: "User ID"
  },
  {
    id: 1,
    label: "Patient Name"
  },
  {
    id: 2,
    label: "Email"
  },
  {
    id: 3,
    label: "Plan"
  },
  {
    id: 4,
    label: "Provider"
  },
  {
    id: 5,
    label: "Consultations"
  }
  // {
  //   id: 6,
  //   label: "Status"
  // }
];
export const emailHeader = [
  {
    id: 0,
    label: "Date"
  },
  {
    id: 6,
    label: "Email"
  },
  {
    id: 4,
    label: "Role"
  },
  {
    id: 9,
    label: ""
  }
];
export const PermissionHeader = [
  {
    id: 0,
    label: "Permission"
  },
  {
    id: 6,
    label: "CRUD"
  },
  {
    id: 3,
    label: ""
  }
];
export const adminHeader = [
  {
    id: 0,
    label: "Admin Name"
  },
  {
    id: 1,
    label: "Role"
  }
];
export const roleHeader = [
  {
    id: 0,
    label: "Role"
  },
  {
    id: 5,
    label: "Permission"
  },
  {
    id: 2,
    label: ""
  }
];

export const HCPHeader = [
  {
    id: 0,
    label: "Entry Date"
  },
  {
    id: 8,
    label: "First Name"
  },
  {
    id: 1,
    label: "Last Name"
  },
  {
    id: 2,
    label: "Specialization"
  },
  {
    id: 10,
    label: "Status"
  },
  {
    id: 4,
    label: "Year"
  }
];
export const financeHeader = [
  {
    id: 0,
    label: " Date"
  },
  {
    id: 1,
    label: "Time"
  },
  {
    id: 2,
    label: "Name"
  },

  // {
  //   id: 30,
  //   label: "Plan Type",
  // },
  // {
  //   id: 34,
  //   label: "Provider",
  // },
  {
    id: 4,
    label: "Amount"
  }
];
export const financeHeader2 = [
  {
    id: 0,
    label: " Amount"
  },
  {
    id: 1,
    label: "Customer"
  },
  {
    id: 2,
    label: "Plan Type"
  },

  {
    id: 30,
    label: "Provider"
  },
  {
    id: 34,
    label: "Paid On"
  }
];
export const payoutHeaderss1 = [
  {
    id: 234,
    label: "Doctor"
  },
  {
    id: 235,
    label: "Provider"
  },
  {
    id: 2350,
    label: "Amount"
  },
  {
    id: 2,
    label: "Received On"
  }
];
export const referralHeader = [
  {
    id: 0,
    label: "Date"
  },
  {
    id: 1,
    label: "Referral ID"
  },
  {
    id: 2,
    label: "Doctor"
  },

  {
    id: 3,
    label: "Patient"
  },

  {
    id: 8,
    label: "Type"
  }
];

export const subscriptionHeader = [
  {
    id: 8,
    label: "Name of plan"
  },
  {
    id: 1,
    label: "Amount"
  },
  {
    id: 2,
    label: " Description"
  },
  /* {
    id: 4,
    label: " Provider",
  }, */
  {
    id: 6,
    label: "Duration"
  },
  {
    id: 8,
    label: ""
  }
];

export const hmoPlansTableHeader = [
  {
    id: 8,
    label: "Name of plan"
  },
  /*   {
    id: 1,
    label: "Amount",
  }, */
  {
    id: 2,
    label: " Description"
  },
  /* {
    id: 4,
    label: " Provider",
  }, */
  {
    id: 6,
    label: "Access"
  },
  {
    id: 8,
    label: ""
  }
];
export const payoutHeader = [
  {
    id: 0,
    label: "Doctor"
  },
  {
    id: 1,
    label: "Amount Paid"
  },
  {
    id: 2,
    label: "Status"
  },

  {
    id: 5,
    label: "Paid On"
  }
];

export const doctorPayoutHeader = [
  {
    id: 0,
    label: "Paid On"
  },
  {
    id: 2,
    label: " Doctor"
  },

  {
    id: 5,
    label: "Amount"
  },
  {
    id: 6,
    label: "Status"
  }
];
export const payoutHeaders = [
  /* {
    id: 1,
    label: "Time",
  }, */
  {
    id: 2,
    label: "Name"
  },
  {
    id: 4,
    label: "Amount"
  },
  {
    id: 0,
    label: "Earned on"
  }
];
export const pendingHeader = [
  {
    id: 0,
    label: "Date"
  },
  {
    id: 1,
    label: "Heala  ID"
  },
  {
    id: 2,
    label: " Name"
  },

  {
    id: 3,
    label: "Amount paid"
  },
  {
    id: 4,
    label: "Account Details"
  },
  {
    id: 5,
    label: "Bank"
  }
];
export const pendingHeader2 = [
  {
    id: 0,
    label: "Date"
  },
  {
    id: 1,
    label: "Heala  ID"
  },
  {
    id: 2,
    label: " Name"
  },

  {
    id: 3,
    label: "Amount paid"
  },
  {
    id: 4,
    label: "Account Details"
  },
  {
    id: 5,
    label: "Bank"
  }
];
export const editManagement = [
  {
    id: 110,
    label: ""
  },
  {
    id: 0,
    label: "Endpoints"
  },
  {
    id: 1,
    label: "Operations"
  }
];
export const viewRefferalHeader = [
  {
    id: 0,
    label: "Name"
  },
  {
    id: 1,
    label: "Category"
  },
  {
    id: 2,
    label: " Available Time"
  },

  {
    id: 4,
    label: ""
  },
  {
    id: 5,
    label: ""
  }
];

export const allConsultationsHeader = [
  { id: 100, label: "Date" },
  { id: 101, label: "Doctor" },
  { id: 107, label: "Patient" },
  { id: 102, label: "Symptoms" },
  { id: 105, label: "Contact" },
  { id: 133, label: "Type" },
  { id: 1232, label: "Status" }
];

export const allDisputesHeader = [
  { id: 100, label: "Date" },
  { id: 101, label: "Doctor" },
  { id: 107, label: "Patient" },
  { id: 102, label: "Symptoms" },
  { id: 105, label: "Contact" },
  { id: 133, label: "Type" },
  {
    id: 1232,
    label: (
      <span>
        Consultation <br /> status
      </span>
    )
  }
];

// Patient > Consultation Route Table
export const consultationsHeadCells = [
  { id: 100, label: "Date" },
  { id: 101, label: "Patients" },
  { id: 102, label: "Symptoms" },
  { id: 105, label: "Contact" },
  { id: 133, label: "Type" },
  { id: 1232, label: "Status" }
];
export const consultationsHeadCells4 = [
  { id: 100, label: "Date" },
  { id: 101, label: "Doctor" },
  { id: 102, label: "Symptoms" },
  { id: 105, label: "Contact" },
  { id: 133, label: "Type" },
  { id: 1232, label: "Status" }
];

export const patientAppointmentHeadCells = [
  { id: 100, label: "Doctor" },
  { id: 101, label: "Date" },
  { id: 102, label: "Time" },
  { id: 103, label: "Contact Medium" },
];

export const doctorAppointmentHeadCells = [
  { id: 100, label: "Patient" },
  { id: 101, label: "Date" },
  { id: 102, label: "Time" },
  { id: 103, label: "Contact Medium" },
];

// Patients > Prescription Route Table
export const prescriptionsHeadCells = [
  { id: 200, label: "Date" },
  { id: 201, label: "Details" },
  { id: 202, label: "Dosage" }
];

// Patients > Medications Route Table
export const medicationsHeadCells = [
  { id: 300, label: "Date" },
  { id: 301, label: "Drug" },
  { id: 302, label: "Dosage" },
  { id: 309, label: "Interval" },
  { id: 303, label: "Doctor" }
];

// ------------------------------------

// Dashboard Route TableHeader
export const waitingHeadCells = [
  { id: 0, label: "User ID" },
  { id: 1, label: "Name" },
  { id: 2, label: "Waiting Time" },
  { id: 3, label: "" }
];

export const availabilityHeadCells = [
  { id: 0, label: "Doctor ID" },
  { id: 1, label: "Name" },
  { id: 2, label: "Day" },
  { id: 3, label: "Start Time" },
  { id: 300, label: "End Time" }
];
export const availabilityHeadCells10 = [
  { id: 2, label: "Day" },
  { id: 3, label: "Start Time" },
  { id: 300, label: "End Time" }
];

export const hcpsHeadCells = [
  { id: 0, label: "Medical ID" },
  { id: 1, label: "Name" },
  { id: 2, label: "Email" },
  { id: 3, label: "Category" },
  { id: 4, label: "Consultations" },
  { id: 5, label: "Provider" },
  { id: 6, label: "Status" }
];
export const medicalHeadCells = [
  { id: 0, label: "Drug Name" },
  { id: 1, label: "Quantity per Pack" },
  { id: 2, label: "Price" }
];

// Partner's HEADCELLS
export const partnersHeadCells = [
  { id: 0, label: "Admin's Name" },
  { id: 3, label: "Email" },
  { id: 1, label: "Category" }
  //   { id: 2, label: "Deep link" },
];
export const illnesssHeadCells = [
  { id: 0, label: "Date" },
  { id: 1, label: "Description" },
  { id: 2, label: "Name" },
  { id: 3, label: "" }
];
export const partnersHeadCells2 = [
  { id: 1, label: "User Types" },
  { id: 0, label: "Providers" },
  { id: 2, label: "" }
];

// Messages's HEADCELLS
export const messagesHeadCells = [
  { id: 0, label: "Recipients" },
  { id: 1, label: "Subject" },
  { id: 2, label: "Date" },
  { id: 3, label: "Time" },
  { id: 4, label: "" }
];
// Doctor Patients HEADCELLS
export const hcpPatientsHeadCells = [
  { id: 0, label: "Patient ID" },
  { id: 1, label: "Patient Name" },
  { id: 2, label: "Gender" }
];

export const testTableHeadCells = [
  { id: 0, label: "Test Name" },
  { id: 1, label: "Price (Naira)" },
  { id: 2, label: "Turnaround time (TAT)" },
  { id: 4, label: "" }
];

export const singleHmoTableHeadCells = [
  { id: 1, label: "HMO ID" },
  { id: 0, label: "NAME" },
  { id: 2, label: "NOC" },
  { id: 5, label: "HMO PLAN" },
  { id: 6, label: "EXPIRY" },
  { id: 7, label: "STATUS" },
  { id: 9, label: "" }
];

export const hospitalTableHeadCells = [
  { id: 1, label: "Providers" },
  { id: 0, label: "Users" },
  { id: 3, label: "Doctors" },
  { id: 4, label: "Admins" },
  { id: 20, label: "Deep Links" }
];
export const hospitalTableHeadCells20 = [
  // { id: 1, label: "Providers" },
  { id: 0, label: "Users" },
  // { id: 3, label: "Doctors" },
  // { id: 4, label: "Admins" },
  { id: 20, label: "" }
];
export const hospitalTableHeadCells10 = [
  { id: 1, label: "Providers" },
  { id: 10, label: "Doctors" },
  { id: 0, label: "Users" },
  { id: 20, label: "Enrolles" },
  { id: 3, label: "Admins" },
  { id: 100, label: "Deep Links" }
];
export const hospitalTableHeadCells2 = [
  { id: 1, label: "Providers" },
  { id: 0, label: "Users" },
  { id: 3, label: "Doctors" },
  { id: 4, label: "Admins" },
  { id: 2, label: "Enrolees" },
  { id: 20, label: "Deep Links" }
];
export const hospitalTableHeadCells5 = [
  { id: 1, label: "Providers" },
  { id: 0, label: "Users" },
  { id: 3, label: "Enrollees" },
  { id: 4, label: "Admins" }
  // { id: 45, label: "" },
];

export const prescriptionTable = [
  {
    id: 0,
    label: "Drug Name"
  },
  {
    id: 1,
    label: "Dosage"
  },
  {
    id: 2,
    label: "Frequency"
  },
  {
    id: 3,
    label: "Mode"
  },
  {
    id: 4,
    label: "Instructions"
  },
  {
    id: 5,
    label: "Price"
  }
];

export const testRefTableHeader = [
  {
    id: 0,
    label: "Test Name"
  },
  {
    id: 1,
    label: "Turnaround time (TAT)"
  },
];

export const providersHeader = [
  {
    id: 1,
    label: "Name"
  },
  {
    id: 2,
    label: "Doctors"
  },

  {
    id: 5,
    label: ""
  }
];

export const processDoctorsPayoutHeader = [
  {
    id: 0,
    label: "Heala ID"
  },
  {
    id: 1,
    label: "Name"
  },
  {
    id: 2,
    label: "Amount"
  },
  {
    id: 3,
    label: "Bank Status"
  },
  {
    id: 4,
    label: ""
  }
];

export const patientPrescriptionHeadCells = [
  {
    id: 0,
    label: "Date"
  },
  {
    id: 1,
    label: "Doctor"
  },
  {
    id: 2,
    label: "Drugs"
  },
  {
    id: 3,
    label: ""
  }
];
