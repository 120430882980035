import { SvgIcon } from "@mui/material";
import React from "react";

function Lab(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.5 8.19H15.61C13.24 8.19 11.31 6.26 11.31 3.89V1C11.31 0.45 10.86 0 10.31 0H6.07C2.99 0 0.5 2 0.5 5.57V14.43C0.5 18 2.99 20 6.07 20H13.93C17.01 20 19.5 18 19.5 14.43V9.19C19.5 8.64 19.05 8.19 18.5 8.19ZM9.5 15.75H5.5C5.09 15.75 4.75 15.41 4.75 15C4.75 14.59 5.09 14.25 5.5 14.25H9.5C9.91 14.25 10.25 14.59 10.25 15C10.25 15.41 9.91 15.75 9.5 15.75ZM11.5 11.75H5.5C5.09 11.75 4.75 11.41 4.75 11C4.75 10.59 5.09 10.25 5.5 10.25H11.5C11.91 10.25 12.25 10.59 12.25 11C12.25 11.41 11.91 11.75 11.5 11.75Z" />
    </SvgIcon>
  );
}

export default Lab;
