import React, { useEffect, useMemo, useState } from "react";
import useAlert from "hooks/useAlert";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { isSelected } from "helpers/isSelected";
import { useTheme } from "@mui/material/styles";
import { useParams, Link } from "react-router-dom";
import { defaultPageInfo } from "helpers/mockData";
import MainModal from "components/modals/MainModal";
import TableLayout from "components/layouts/TableLayout";
import { payDoctors } from "components/graphQL/Mutation";
import { EditDelBtn } from "components/Buttons/EditDelBtn";
import { CustomButton, Loader } from "components/Utilities";
import { getDoctorsForPayout } from "components/graphQL/useQuery";
import { NoData, EmptyTable, EnhancedTable } from "components/layouts";
import { useLazyQuery, useMutation, NetworkStatus } from "@apollo/client";
import { processDoctorsPayoutHeader } from "components/Utilities/tableHeaders";
import {
  Grid,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  Chip
} from "@mui/material";
import { formatNum, removeEmptyStringValues } from "helpers/func";
import { useTableActions } from "components/hooks/useTableActions";
import {
  changeTableLimit,
  fetchMoreData,
  handlePageChange
} from "helpers/filterHelperFunctions";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    display: "grid",
    placeContent: "center",
    marginLeft: "1rem",
    background: theme.palette.common.lightGreen
  },
  button: {
    "&.css-1zf5oc-MuiButtonBase-root-MuiButton-root": {
      background: "#fff",
      color: theme.palette.common.grey,
      textTransform: "none",
      borderRadius: "2rem",
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      maxWidth: "10rem",

      "&:hover": {
        background: "#fcfcfc"
      },

      "&:active": {
        background: "#fafafa"
      },

      "& .css-9tj150-MuiButton-endIcon>*:nth-of-type(1)": {
        fontSize: "1.2rem"
      },

      "& .css-9tj150-MuiButton-endIcon": {
        marginLeft: ".3rem",
        marginTop: "-.2rem"
      }
    }
  },

  tableCell: {
    "&.MuiTableCell-root": {
      color: "rgb(0 0 0)",
      fontWeight: 400,
      fontSize: "1.25rem"
    }
  },

  badge: {
    "&.MuiChip-root": {
      width: "70px",
      fontSize: "1.3rem !important",
      height: "3rem",
      borderRadius: "15px"
    }
  }
}));

/* 
  TEST 
  - TEST THAT THE NEW SELECTION WORKS WELL WITH THE PAGINATION ** talk to bradley
  - TRY TO TEST THE NEW REDUX
*/

const ProcessDoctorsPayouts = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const { selectSingleRow } = useTableActions();
  const { displayAlert, getErrorMsg } = useAlert();
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  const { selectedRows } = useSelector((state) => state.table);
  const [payoutDoctors, { loading: payoutDocsLoading }] =
    useMutation(payDoctors);
  const [
    fetchDoctorPayouts,
    { data, loading, error, refetch, variables, networkStatus }
  ] = useLazyQuery(getDoctorsForPayout, {
    variables: { provider: id, orderBy: "-createdAt" },
    notifyOnNetworkStatusChange: true
  });

  const payoutBtn = useMemo(() => {
    return {
      background: theme.palette.common.blue,
      hover: "#2b4a94",
      active: theme.palette.primary.dark,
      disabled: "#868686"
    };
  }, [theme.palette.common.blue, theme.palette.primary.dark]);

  const selectedDoctors = useMemo(
    () => Object.values(selectedRows || {}),
    [selectedRows]
  );

  const total = useMemo(() => {
    const total = (selectedDoctors || []).reduce(
      (prev, current) => Number(current?.balance) + Number(prev),
      [0]
    );
    return total;
  }, [selectedDoctors]);

  const filteredDoctors = useMemo(() => {
    const filtered = (doctors || []).filter((doctor) =>
      Boolean(doctor?.accountDetails)
    );
    return filtered;
  }, [doctors]);

  useEffect(() => {
    fetchDoctorPayouts();
  }, [fetchDoctorPayouts]);

  useEffect(() => {
    const doctors = data?.getPayoutDoctors?.profile;
    // const doctorsIdsArray = (doctors || []).map((doctor) => doctor?._id);
    // const filteredSelectedRows = (selectedRows || []).filter((doctorId) =>
    //   doctorsIdsArray?.includes(doctorId)
    // );
    // setSelectedRows(filteredSelectedRows);
    setPageInfo(data?.getPayoutDoctors?.pageInfo || defaultPageInfo);
    setDoctors(doctors || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <NoData error={error} />;

  return (
    <Grid container direction="column" rowSpacing={2}>
      <>
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ paddingBottom: "3rem" }}
        ></Grid>
        <TableLayout
          actions={
            selectedDoctors.length > 0 ? (
              <Grid sx={{ marginRight: "1rem" }}>
                <CustomButton
                  title={`Process payout ( ${selectedDoctors.length} )`}
                  type={payoutBtn}
                  isSubmitting={false}
                  onClick={() => setOpen(true)}
                />
              </Grid>
            ) : (
              <div></div>
            )
          }
        >
          {loading ? (
            <Loader />
          ) : doctors.length > 0 ? (
            <Grid item container>
              <EnhancedTable
                headCells={processDoctorsPayoutHeader}
                rows={filteredDoctors}
                paginationLabel="payout per page"
                hasCheckbox={true}
                changeLimit={async (e) => {
                  const filterVar = removeEmptyStringValues(variables);
                  changeTableLimit(fetchDoctorPayouts, {
                    ...filterVar,
                    first: e,
                    orderBy: "-createdAt"
                  });
                }}
                dataPageInfo={pageInfo}
                handlePagination={async (page) => {
                  const filterVar = removeEmptyStringValues(variables);
                  handlePageChange(fetchDoctorPayouts, page, pageInfo, {
                    orderBy: "-createdAt",
                    ...filterVar
                  });
                }}
                fetchData={fetchDoctorPayouts}
                handleChangePage={fetchMoreData}
              >
                {doctors.map((row, index) => {
                  const {
                    _id,
                    balance,
                    firstName,
                    lastName,
                    dociId,
                    accountDetails
                  } = row;
                  const isItemSelected = isSelected(_id, selectedRows);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={_id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            selectSingleRow(e.target.checked, _id, doctors);
                          }}
                          disabled={Boolean(!accountDetails)}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId
                          }}
                        />
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                      >
                        {dociId}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "left"
                          }}
                        >
                          <span style={{ fontSize: "1.25rem" }}>{`${
                            firstName && firstName
                          } ${lastName && lastName}`}</span>
                        </div>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {balance && `N${balance}`}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        {accountDetails ? (
                          <Chip
                            label={"True"}
                            className={classes.badge}
                            style={{
                              background: "#e7fbe7",
                              color: "#56e355"
                            }}
                          />
                        ) : (
                          <Chip
                            label={"False"}
                            className={classes.badge}
                            style={{
                              background: "#FFECEC",
                              color: "#FF6347"
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <div
                          style={{
                            display: "flex"
                          }}
                        >
                          <EditDelBtn
                            type="edit"
                            text="View Doctor"
                            noIcon={true}
                            LinkComponent={Link}
                            to={`/hcps/${_id}`}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </EnhancedTable>
            </Grid>
          ) : (
            <EmptyTable
              headCells={processDoctorsPayoutHeader}
              paginationLabel="Providers  per page"
            />
          )}
        </TableLayout>
      </>
      <MainModal
        isOpen={open}
        setIsOpen={setOpen}
        headerText={`Confirm payout to ${selectedDoctors?.length} ${
          selectedDoctors?.length > 1 ? "doctors" : "doctor"
        }`}
      >
        <Grid>
          <Grid
            sx={{
              display: "flex",
              backgroundColor: "#3E5EA9",
              justifyContent: "space-between",
              padding: "1.5rem 2rem",
              marginTop: "2rem"
            }}
          >
            <Typography sx={{ color: "#ffffff" }}>
              Doctor&apos;s name
            </Typography>
            <Typography sx={{ color: "#ffffff" }}>Amount (&#8358;)</Typography>
          </Grid>

          <Grid
            sx={{
              backgroundColor: "#F9F9F9"
            }}
          >
            {selectedDoctors.map((doctor, idx) => {
              const { balance, firstName, lastName } = doctor;

              return (
                <Grid
                  key={idx}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1.5rem 2rem"
                  }}
                >
                  <Typography
                    sx={{ color: "#0C1322" }}
                  >{`${firstName} ${lastName}`}</Typography>
                  <Typography
                    sx={{ color: "#0C1322" }}
                  >{`${balance}`}</Typography>
                </Grid>
              );
            })}
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1.5rem 2rem",
              backgroundColor: "#F9F9F9",
              margin: "2rem 0rem"
            }}
          >
            <Typography sx={{ color: "#3E5EA9" }}>Total amount:</Typography>
            <Typography sx={{ color: "#0C1322" }}>{`${formatNum(
              Number(total)
            )}`}</Typography>
          </Grid>

          <Grid sx={{ display: "flex" }} justifyContent="flex-end">
            <CustomButton
              title="Confirm / Pay"
              type={payoutBtn}
              isSubmitting={
                payoutDocsLoading || networkStatus === NetworkStatus.refetch
              }
              onClick={async () => {
                try {
                  const doctorIdsToBePaid = (selectedDoctors || []).map(
                    (doc) => doc._id
                  );
                  const { data } = await payoutDoctors({
                    variables: { doctors: doctorIdsToBePaid }
                  });
                  if (data) {
                    displayAlert("success", "Payout successfully!");
                    await refetch();
                    setOpen(false);
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                  const errMsg = getErrorMsg(error);
                  displayAlert("error", errMsg);
                }
              }}
            />
          </Grid>
        </Grid>
      </MainModal>
    </Grid>
  );
};

export default ProcessDoctorsPayouts;
