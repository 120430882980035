import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { useFormik } from "formik";
import useAlert from "hooks/useAlert";
import { Edit } from "@mui/icons-material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createConsultation } from "components/graphQL/Mutation";
import { formulateConsultationVarForReferral } from "helpers/func";
import { referPatientValidationSchema } from "helpers/validationSchemas";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import {
  getDocAvailability,
  getDoctorsProfile,
} from "components/graphQL/useQuery";

export const ReferPatientForm = ({ prevConsultation, onSuccess }) => {
  const [options, setOptions] = useState([]);
  const { getErrorMsg, displayAlert } = useAlert();
  const [disableFee, setDisableFee] = useState(true);
  const [disableRefNote, setDisableRefNote] = useState(true);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedDoctorTimes, setSelectedDoctorTimes] = useState([]);
  const [
    createReferralConsultation,
    { loading: creatingConsultation, error: consultError },
  ] = useMutation(createConsultation, {
    onCompleted: () => {
      displayAlert("success", "Referral consultation created");
      onSuccess?.();
    },
  });
  const { doctorNote, contactMedium } = prevConsultation || {};

  const formik = useFormik({
    initialValues: {
      doctor: "",
      fee: "",
      description: doctorNote || "",
      date: "",
      time: "",
      contactMedium: contactMedium || "voice",
    },
    onSubmit: (values) => onHandleSubmit(values),
    validationSchema: referPatientValidationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const { handleChange, setFieldValue, values, errors } = formik;

  const [fetchDoctors, { error, loading }] = useLazyQuery(getDoctorsProfile, {
    onCompleted: (data) => {
      setOptions(data?.doctorProfiles?.profile || []);
    },
  });

  const [getSelectedDoctorTimes, { loading: gettingDoctorTimes }] =
    useLazyQuery(getDocAvailability, {
      onCompleted: (data) => {
        const times = (data?.getDoctorAvailabilityForDate?.times || [])
          .filter((time) => time?.available === true)
          .map((time) => time?.start);

        setSelectedDoctorTimes(times);
      },
    });

  const debouncedFetchOptions = useMemo(
    () => debounce(fetchDoctors, 500),
    [fetchDoctors]
  );

  // Cleanup the debounce function on component unmount
  useEffect(() => {
    return () => debouncedFetchOptions.cancel();
  }, [debouncedFetchOptions]);

  useEffect(() => {
    if (error) {
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (consultError) {
      const errMsg = getErrorMsg(consultError);
      displayAlert("error", errMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultError]);

  const handleInputChange = async (_, value) => {
    try {
      if (value) {
        debouncedFetchOptions({
          variables: { search: value },
        });
        return;
      }
      setOptions([]);
    } catch (error) {
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onHandleSubmit = (values) => {
    try {
      const variables = formulateConsultationVarForReferral(
        values,
        prevConsultation
      );

      createReferralConsultation({
        variables,
      });
    } catch (error) {
      const errMsg = getErrorMsg(errMsg);
      displayAlert("error", errMsg);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleSelect = (_, newValue) => {
    setFieldValue("fee", newValue?.fee || "", true);
    setFieldValue("doctor", newValue?._id, true);
    setSelectedDoctor(newValue);

    setSelectedDoctorTimes([]);
    setFieldValue("time", "", true);
    setFieldValue("date", "", true);
  };

  return (
    <form style={{ marginTop: "4rem" }} onSubmit={formik.handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Autocomplete
          options={options}
          onInputChange={handleInputChange}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          onChange={handleSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Doctor"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          isOptionEqualToValue={(option, value) =>
            option.dociId === value.dociId
          }
        />

        {/* SELECTED DOCTOR INFO */}
        {selectedDoctor && (
          <div style={{ marginTop: "1rem" }}>
            <h4>Selected Doctor:</h4>
            <div className="flex border rounded-lg !p-4 !space-x-4">
              <img
                src={selectedDoctor.picture}
                alt={`${selectedDoctor.firstName}'s profile`}
                style={{ width: "100px" }}
              />
              <div>
                <p>
                  <strong>Name:</strong> {selectedDoctor.firstName}{" "}
                  {selectedDoctor.lastName}
                </p>
                <p>
                  <strong>Email:</strong> {selectedDoctor.email}
                </p>
                <p>
                  <strong>Phone Number:</strong> {selectedDoctor.phoneNumber}
                </p>
              </div>
            </div>
          </div>
        )}

        {selectedDoctor && (
          <div>
            <label htmlFor="doctor-fee" className="inline-block">
              Doctor Fee
            </label>
            <div className="relative">
              <input
                name="fee"
                value={values?.fee}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9]/g, "");
                  setFieldValue("fee", newValue);
                }}
                aria-label="Doctor fee:"
                className="block w-full border !p-4 rounded-lg"
                disabled={disableFee}
              />
              <button
                type="button"
                onClick={() => setDisableFee(false)}
                className="text-sm absolute -right-2 -top-2 bg-gray-200 border border-gray-300 rounded-full p-1"
              >
                <Edit size={5} />
              </button>
            </div>
          </div>
        )}

        <div>
          <label htmlFor="doctors-note"> Referral Note:</label>
          <div className="relative">
            <textarea
              name="description"
              rows={4}
              value={values?.description}
              onChange={handleChange}
              className="block w-full border rounded-lg p-4"
              disabled={disableRefNote}
            ></textarea>
            <button
              type="button"
              onClick={() => setDisableRefNote(false)}
              className="text-sm absolute -right-2 -top-2 bg-gray-200 border border-gray-300 rounded-full p-1"
            >
              <Edit size={5} />
            </button>
          </div>
        </div>

        <div>
          <label htmlFor="date">Date:</label>
          <input
            type="date"
            name="date"
            className="w-full border rounded-lg p-5"
            value={values?.date}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("date", value, true);
              getSelectedDoctorTimes({
                variables: {
                  doctorId: selectedDoctor?._id,
                  date: value,
                },
              });
            }}
          />
        </div>

        {gettingDoctorTimes ? (
          <CircularProgress color="primary" size={20} sx={{ margin: "auto" }} />
        ) : (
          <div>
            <label htmlFor="time">Time:</label>
            <select
              name="time"
              value={values?.time}
              onChange={handleChange}
              className="w-full border rounded-lg p-5"
            >
              <option value="">
                {!values?.date ||
                values?.date === "" ||
                (selectedDoctorTimes || []).length > 0
                  ? "Select time"
                  : "No available time"}
              </option>
              {(selectedDoctorTimes || []).map((time, idx) => {
                return (
                  <option key={idx} value={time}>
                    {time}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <div>
          <label htmlFor="contact-medium">Contact medium:</label>
          <select
            name="contactMedium"
            value={values?.contactMedium}
            onChange={handleChange}
            className="w-full border rounded-lg p-5"
          >
            <option value="chat">Chat</option>
            <option value="voice">Audio</option>
            <option value="video">Video</option>
          </select>
        </div>
      </Grid>

      <div className="mt-10">
        {errors && (
          <p className="text-red-500 text-[14px] text-center m-0 p-0">
            {errors[Object.keys(errors)[0]]}
          </p>
        )}
        <button
          type="submit"
          disabled={creatingConsultation || gettingDoctorTimes}
          className="w-full rounded-lg bg-black text-white p-5"
        >
          {creatingConsultation ? (
            <CircularProgress sx={{ color: "#fff" }} size={16} />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
};

ReferPatientForm.propTypes = {
  prevConsultation: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
