import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import MainModal from "./MainModal";
import { Message } from "components/Message/Message";
import { useTextCipher } from "hooks/useTextCipher";
import { useFireStore } from "hooks/useFireStore";
import { Loader } from "components/Utilities";

export const isMsgFromPatient = (message, patientId) =>
  message.idFrom === patientId;

export const ViewConsultationSummary = ({
  isOpen,
  setIsOpen,
  headerText,
  consultationInfo,
}) => {
  const { decrypt } = useTextCipher();
  const [error, setError] = useState(null);
  const { fetchAllDocuments } = useFireStore();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(null);
  const { patient, doctor, _id } = consultationInfo || {};
  const roomId = `${_id}_${doctor?._id}_${patient?._id}`;

  useEffect(() => {
    setLoading(true);
    fetchAllDocuments(roomId)
      .then((res) => {
        setMessages(res);
        setLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setLoading(false);
        setError("Something went wrong");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const decryptedMessages = useMemo(() => {
    return (messages || []).map((message) => {
      const decryptedContent = decrypt(message?.content);
      const content =
        decryptedContent && decryptedContent !== ""
          ? decryptedContent
          : message?.content;
      const decryptedReplyToContent = decrypt(message?.replyTo?.content);
      const replyTo =
        decryptedReplyToContent && decryptedReplyToContent !== ""
          ? decryptedReplyToContent
          : message?.replyTo?.content;
      return {
        ...message,
        replyTo: message.replyTo
          ? {
              ...message.replyTo,
              content: replyTo,
            }
          : null,
        content,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  return (
    <MainModal isOpen={isOpen} setIsOpen={setIsOpen} headerText={headerText}>
      {loading ? (
        <Loader />
      ) : error ? (
        <p className="text-red-500 text-[14px] text-center">{error}</p>
      ) : decryptedMessages?.length < 1 ? (
        <p className="text-center text-primary">No messages</p>
      ) : (
        <div className="relative">
          <div className="bg-white flex sticky -top-[33px] p-[8px] border-b border-gray-400 z-20">
            <div className="bg-[#3E5EA9] text-white p-[8px] text-[14px]">
              Doctor
            </div>
            <div className="bg-[#F5F5F5] text-black p-[8px] text-[14px]">
              Patient
            </div>
          </div>
          <div className=" space-y-10 my-5">
            {decryptedMessages.map((message, id) => {
              const fromPatient = isMsgFromPatient(message, patient?._id);
              const align = fromPatient ? "right" : "left";
              const status = message.status ? "delivered" : "sent";
              return (
                <Message
                  key={`${id}-${message._id}`}
                  align={align}
                  status={status}
                  message={message}
                  showStatus={message.idFrom === patient?._id}
                  consultationInfo={consultationInfo}
                />
              );
            })}
          </div>
        </div>
      )}
    </MainModal>
  );
};

ViewConsultationSummary.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  consultationInfo: PropTypes.object.isRequired,
};
